<script lang="ts">
	import { createEventDispatcher, getContext } from "svelte";

	import { getAreaId } from "@xbs/lib-kanban";

	import type {
		IApi,
		TCardsMap,
		IColumn,
		IRow,
		IRowShape,
		TEventDispatcher,
		TMenuItemsFn,
	} from "@xbs/lib-kanban";
	import Icon from "./../lib/Icon.svelte";

	import { DropDownMenu } from "@xbs/svelte-menu";

	export let row: IRow = {
		id: "default",
		label: "default",
		collapsed: false,
	};
	export let rows: IRow[] = [];
	export let api: IApi;

	export let collapsable = true;

	const _ = getContext<any>("wx-i18n").getGroup("kanban");
	const dispatch: TEventDispatcher = createEventDispatcher();

	function handleRowCollapse() {
		dispatch("action", {
			action: "update-row",
			data: {
				id: row.id,
				row: {
					collapsed: !row.collapsed,
				},
			},
		});
	}

	const { readonly, rowShape, cardsMap, columns } = api.getReactiveState();

	$: edit = $readonly.edit;

	let renaming = false;
	let rowLabel = null;
	$: rowIndex = rows.findIndex(c => c.id === row.id);

	function endRenaming() {
		if (renaming && rowLabel?.trim()) {
			dispatch("action", {
				action: "update-row",
				data: {
					id: row.id,
					row: {
						label: rowLabel,
					},
				},
			});
		}
		renaming = false;
		rowLabel = null;
	}

	function handleRowRename() {
		if (!edit) {
			return;
		}
		renaming = true;
	}
	function handleLabelInput(e: any) {
		rowLabel = e.target.value;
	}
	function handleInputKeypress(e: KeyboardEvent) {
		if (e.charCode === 13) {
			endRenaming();
		}
	}

	function focus(node: HTMLElement) {
		node.focus();
	}

	let menuOpen = false;
	function handleMenuClick() {
		menuOpen = true;
	}

	function handleMove(dir: "up" | "down") {
		const beforeIndex = dir === "up" ? rowIndex - 1 : rowIndex + 2;
		const before = rows[beforeIndex]?.id;

		dispatch("action", {
			action: "move-row",
			data: {
				id: row.id,
				before,
			},
		});
	}

	function handleMenuAction(ev: any) {
		const action = ev.detail.action;
		if (action) {
			if (action.onClick) {
				action.onClick({
					id: action.id,
					item: action,
					row,
				});
				return;
			}

			switch (action.id) {
				case "set-edit":
					handleRowRename();
					break;
				case "delete-row":
					dispatch("action", {
						action: "delete-row",
						data: {
							id: row.id,
						},
					});
					break;
				case "move-row:up":
					handleMove("up");
					break;
				case "move-row:down":
					handleMove("down");
					break;
			}
		}

		menuOpen = null;
	}

	let labelEl: HTMLElement;
	let menuEl: HTMLElement;

	const getMenuItems = (
		row: IRow,
		rowIndex: number,
		rows: IRow[],
		rowShape: IRowShape
	) => {
		const menuItems = (
			rowShape.menu.items as TMenuItemsFn<{
				row: IRow;
				rows: IRow[];
				rowIndex: number;
			}>
		)({
			rows,
			rowIndex,
			row: row,
		});

		if (!menuItems || !menuItems.length) {
			return null;
		}

		return menuItems.map(item => {
			return {
				...item,
				text: _(item.text),
				css: item.disabled ? "disabled" : "",
			};
		});
	};

	$: menuItems = getMenuItems(row, rowIndex, rows, $rowShape);
	$: isMenuVisible = $rowShape.menu.show && !!menuItems && edit && !renaming;

	function buildRowCss(
		row: IRow,
		rowShape: IRowShape,
		columns: IColumn[],
		cardsMap: TCardsMap
	) {
		let rowClass = "wx-row";
		if (row.collapsed) rowClass += " wx-collapsed";
		if (row.css) rowClass += " " + row.css;
		if (rowShape && rowShape.css) {
			let cards = [];
			columns.forEach(
				column =>
					(cards = cards.concat(
						cardsMap[getAreaId(column.id, row.id)]
					))
			);
			rowClass += " " + rowShape.css(row, cards);
		}
		return rowClass;
	}

	$: rowClass = buildRowCss(row, $rowShape, $columns, $cardsMap);

</script>

<div class={rowClass} class:wx-collapsed={row.collapsed}>
	<div
		class="wx-label {collapsable ? 'collapsable' : ''}"
		data-row-header={row.id}
		bind:this={labelEl}>
		{#if collapsable}
			<div class="wx-label-icon">
				<Icon
					name={row.collapsed ? `wxi-angle-right` : `wxi-angle-down`}
					size={18}
					clickable
					click={handleRowCollapse} />
			</div>
			<div class="wx-label-text" on:dblclick={handleRowRename}>
				{#if renaming}
					<input
						type="text"
						class="wx-input"
						value={row.label}
						on:input={handleLabelInput}
						on:keypress={handleInputKeypress}
						on:blur={endRenaming}
						use:focus />
				{:else}{row.label}{/if}
			</div>
			{#if isMenuVisible}
				<DropDownMenu options={menuItems} on:click={handleMenuAction}>
					<div class="wx-menu" bind:this={menuEl}>
						<Icon name="wxi-dots-h" click={handleMenuClick} />
					</div>
				</DropDownMenu>
			{/if}
		{/if}
		<div class="wx-label-line {collapsable ? 'collapsable' : ''}" />
	</div>
	{#if !row.collapsed}
		<div class="wx-content">
			<slot />
		</div>
	{/if}
</div>

<style>
	.wx-row {
		display: flex;
		flex-direction: column;
		min-width: 100%;
		width: fit-content;
		max-height: 100%;
	}
	.wx-row:not(.wx-collapsed) {
		height: auto;
	}
	.wx-label {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
		z-index: 10;
	}
	.wx-label.collapsable {
		padding: 14px 12px 0 24px;
	}
	.wx-label-text {
		max-width: 50%;
		margin: 0 var(--wx-padding);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.wx-label-line {
		flex: 1 1 auto;
		border-top: var(--wx-kanban-row-line);
	}
	.wx-label-line.collapsable {
		border-top: var(--wx-border);
	}
	.wx-label-icon {
		padding: 0;
	}
	.wx-content {
		display: flex;
		flex-direction: row;
		max-height: 100%;
		height: auto;
	}

	.wx-input {
		padding: var(--wx-input-padding);
		outline: none;
		flex: 1;
		color: var(--wx-color-font);
		width: 100%;
		box-sizing: border-box;
		border: var(--wx-input-border);
		border-radius: var(--wx-input-border-radius);
		background-color: transparent;
	}
	.wx-input:focus {
		border: 1px solid var(--wx-color-primary);
	}

	.wx-menu {
		position: relative;
		margin-right: var(--wx-padding);
	}

</style>
