<script lang="ts">
	import { getContext } from "svelte";
	import type { IApi } from "@xbs/lib-kanban";

	import IconButton from "./IconButton.svelte";
	export let api: IApi;

	const _ = getContext<any>("wx-i18n").getGroup("kanban");

	function handleUndo() {
		api.getStores().data.undo();
	}

	const { history: rHistory } = api.getReactiveState();

	$: canUndo = $rHistory.undo.length > 0;

</script>

<IconButton
	click={handleUndo}
	label={_('Undo')}
	disabled={!canUndo}
	icon="wxi-undo" />
