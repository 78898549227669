<script type="ts">
	import { getContext, tick } from "svelte";

	import Row from "./Row.svelte";
	import Column from "./Column.svelte";
	import ColumnsHeader from "./ColumnsHeader.svelte";

	import type {
		INormalizedCard,
		ICardShape,
		TMenuItemsFn,
		IApi,
		TEditorShape,
		IKanbanEditorConfig,
		TLayoutType,
	} from "@xbs/lib-kanban";

	import { ContextMenu } from "@xbs/svelte-menu";

	import {
		dndAction,
		selection,
		scroll,
		keyManager,
		getAreaId,
		elementsIds,
	} from "@xbs/lib-kanban";
	import VirtualContent from "./VirtualContent.svelte";
	import EditorContainer from "./EditorContainer.svelte";

	export let editorShape: TEditorShape[];
	export let cardTemplate;
	export let layout: TLayoutType;
	export let editor: IKanbanEditorConfig;
	export let api: IApi;

	let contentEl: HTMLElement;
	const _ = getContext<any>("wx-i18n").getGroup("kanban");

	let {
		columns: storeColumns,
		rowKey: storeRowKey,
		rows: storeRows,
		readonly: storeReadonly,
		dragItemId: movedCardId,
		areasMeta,
		cardsMap,
		cardShape: rCardShape,
		cards: rCards,
	} = api.getReactiveState();

	$: ({ edit, select, dnd } = $storeReadonly);

	const { showModal } = getContext<any>("wx-helpers");
	let showMenu = null;
	let menuItems = [];
	const cardMenuItems = {};
	const isMenuVisible = {};
	$: {
		$rCards.forEach(card => {
			cardMenuItems[card.id] = getMenuItems(card, $rCardShape);
			isMenuVisible[card.id] = !!(
				$rCardShape.menu.show && cardMenuItems[card.id]?.length
			);
		});
	}

	function resolver(id) {
		const card = $rCards.find(c => c.id == id);
		if (card) {
			if (api.getState().selected?.length > 1) {
				api.exec("select-card", { id: parseInt(id) });
			}
			menuItems = cardMenuItems[card.id] || [];
		}
		return card;
	}

	const getMenuItems = (card: INormalizedCard, cardShape: ICardShape) => {
		const menuItems = (
			cardShape.menu.items as TMenuItemsFn<{ card: INormalizedCard }>
		)({
			card,
		});

		if (!menuItems || !menuItems.length) {
			return null;
		}

		return menuItems.map(item => {
			return {
				...item,
				text: _(item.text),
			};
		});
	};

	function handleMenuAction(e: any) {
		const { action, item } = e.detail;

		if (action) {
			if (action.onClick) {
				action.onClick({
					id: action.id,
					item: action,
					card: item,
				});
				return;
			}

			switch (action.id) {
				case "delete-card":
					showModal({
						message: _("Would you like to delete this card?"),
					})
						.then(() => {
							api.exec("delete-card", { id: item.id });
						})
						.catch(() => {
							/* handling cancel */
						});
					break;
				case "set-edit":
					api.exec("set-edit", { cardId: item.id });
					break;
				case "duplicate-card":
					api.exec("duplicate-card", {
						id: item.id,
						card: {
							label: `${_("Duplicate of")} ${item.label}`,
						},
					});
					break;
			}
		}
	}

</script>

<div
	class="wx-kanban"
	class:wx-dragged={!!$movedCardId}
	data-wx-widget={elementsIds.kanban}
	use:dndAction={{ api, readonly: dnd === false }}
	use:keyManager={{ api, readonly: edit === false, locale: _ }}
	use:scroll={{ api, tick }}>
	<div
		class="wx-content-wrapper"
		class:wx-virtual-content={layout === 'default:lazy'}
		data-kanban-id={elementsIds.content}
		use:selection={{ api, readonly: select === false }}>
		<!-- svelte-ignore a11y-click-events-have-key-events -->
		<div
			class="wx-content"
			class:wx-virtual-content={layout === 'default:lazy'}
			data-kanban-id={elementsIds.scrollableContent}
			bind:this={contentEl}
			on:click={showMenu}>
			{#if layout === 'default:lazy'}
				<VirtualContent
					{api}
					let:startIndex
					let:endIndex
					let:byRow
					let:virtualContentEl>
					<ColumnsHeader
						columns={$storeColumns}
						areasMeta={$areasMeta}
						on:action
						contentEl={virtualContentEl}
						{api} />
					{#if $storeColumns.length}
						{#each $storeRows as row (row.id)}
							{#if byRow[row.id].visible}
								<Row
									{row}
									rows={$storeRows}
									collapsable={!!$storeRowKey}
									on:action
									{api}>
									{#each $storeColumns as column (column.id)}
										<Column
											{api}
											on:action
											{column}
											{row}
											areaMeta={$areasMeta[getAreaId(column.id, row.id)]}
											cards={byRow[row.id] ? $cardsMap[getAreaId(column.id, row.id)]?.slice(byRow[row.id].startIndex, byRow[row.id].endIndex) : $cardsMap[getAreaId(column.id, row.id)]?.slice(startIndex, endIndex)}
											{cardTemplate}
											{isMenuVisible} />
									{/each}
								</Row>
							{/if}
						{/each}
					{/if}
				</VirtualContent>
			{:else}
				<ColumnsHeader
					columns={$storeColumns}
					areasMeta={$areasMeta}
					on:action
					{contentEl}
					{api} />
				{#if $storeColumns.length}
					{#each $storeRows as row (row.id)}
						<Row
							{row}
							rows={$storeRows}
							collapsable={!!$storeRowKey}
							on:action
							{api}>
							{#each $storeColumns as column (column.id)}
								<Column
									{api}
									on:action
									{column}
									{row}
									areaMeta={$areasMeta[getAreaId(column.id, row.id)]}
									cards={$cardsMap[getAreaId(column.id, row.id)]}
									{cardTemplate}
									{isMenuVisible} />
							{/each}
						</Row>
					{/each}
				{/if}
			{/if}
		</div>
	</div>
	{#if edit && !$movedCardId && editor.show !== false}
		<EditorContainer {api} config={editor} shape={editorShape} />
	{/if}
</div>

<ContextMenu
	at={'left-bottom'}
	options={menuItems}
	bind:handler={showMenu}
	{resolver}
	dataKey="menuId"
	on:click={handleMenuAction} />

<style>
	.wx-kanban * {
		box-sizing: border-box;
	}
	.wx-kanban {
		display: flex;
		flex-direction: row;
		position: relative;

		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;

		background: var(--wx-kanban-background);
		color: var(--wx-color-font);
		overflow: hidden;

		z-index: var(--wx-kanban-z-index);
	}
	:global(.wx-dragged *) {
		user-select: none;
	}
	:global(.wx-dragged .wx-content-wrapper) {
		overflow: hidden;
	}
	.wx-content-wrapper {
		display: flex;
		flex: 1 1 auto;
		overflow: auto;
		position: relative;
	}
	.wx-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex: 1 1 auto;

		position: relative;
		height: max-content;
		padding-bottom: var(--wx-padding);
	}
	.wx-virtual-content {
		height: 100%;
		max-width: 100%;
		overflow: hidden;
		padding-bottom: 0;
	}
	:global(.wx-kanban .wx-dragged-card) {
		color: var(--wx-color-font);
		width: var(--wx-kanban-column-width);
		min-height: 70px;
		position: fixed;
		z-index: 999999;
		user-select: none;
		pointer-events: none;

		box-shadow: var(--wx-kanban-shadow);
	}
	:global(.wx-kanban .wx-dragged-card:after) {
		content: var(--wx-kanban-dragged-cards-count);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		background: var(--wx-color-primary);
		color: var(--wx-color-primary-font);
		position: absolute;
		border-radius: 50%;
		top: -10px;
		right: -10px;
		z-index: 1;
	}
	:global(.wx-ondrag) {
		overflow: hidden;
	}

	/** [todo] We should probably move this to svelte-menu */
	:global(.menu .item) {
		white-space: nowrap;
	}

	:global(.menu .item.disabled) {
		pointer-events: none;
	}
	:global(.menu .item.disabled .value) {
		color: var(--wx-color-font-disabled);
	}

</style>
