<script lang="ts">
	import type { TID } from "@xbs/lib-kanban";

	import { scrollManager } from "@xbs/lib-kanban";

	import { onMount, tick, createEventDispatcher } from "svelte";

	export let items: any[];
	export let scrollToId: TID;

	export let height = "100%";
	export let itemHeight = undefined;

	export let start = 0;
	export let end = 0;

	const dispatch = createEventDispatcher();

	let heightMap = [];
	let rows: HTMLCollectionOf<HTMLElement>;

	let viewport: HTMLElement;
	let contents: HTMLElement;

	let viewportHeight = 0;
	let visibleItems: { index: number; data: any }[];
	let isMounted = false;

	let top = 0;
	let bottom = 0;
	let averageHeight: number;

	$: visibleItems = items.slice(start, end).map((data, i) => {
		return { index: i + start, data };
	});

	$: if (isMounted) init(items, viewportHeight, itemHeight, scrollToId);

	async function scrollTo(id: TID) {
		const item = viewport.querySelector(`[data-id="${id}"]`);
		if (item) {
			return;
		}

		const ind = items.findIndex(item => item.id === id);

		if (ind > -1) {
			viewport.scrollTop = (ind + 1) * averageHeight - viewportHeight / 2;
			updateVisibleItems();

			id = null;
		}
	}

	async function init(
		items: any[],
		viewportHeight: number,
		itemHeight: number,
		scrollToId: TID
	) {
		await tick();

		heightMap.length = items.length;
		heightMap.fill(0);

		const { scrollTop } = viewport;
		let contentHeight = top - scrollTop;

		for (let i = start; i < items.length; i++) {
			if (contentHeight > viewportHeight) {
				end = i;
				break;
			}

			let row = rows[i - start];
			if (!row) {
				end = i + 1;
				await tick();
				row = rows[i - start];
			}

			const rowHeight = itemHeight || row.offsetHeight;
			heightMap[i] = rowHeight;
			contentHeight += rowHeight;
		}

		averageHeight = Math.round((top + contentHeight) / end);
		updateRemainigHeight(averageHeight);

		await tick();
		if (scrollToId) {
			scrollTo(scrollToId);
		}
	}

	function updateRemainigHeight(averageHeight: number) {
		const remaining = items.length - end;
		bottom = remaining * averageHeight;
	}

	async function updateVisibleItems() {
		const { scrollTop } = viewport;

		visibleItems.forEach((item, i) => {
			const { index } = item;
			heightMap[index] = itemHeight || rows[i].offsetHeight;
		});

		let i = 0;
		let rowsHeight = 0;

		while (i < items.length) {
			const rowHeight = heightMap[i] || averageHeight;

			if (rowsHeight + rowHeight > scrollTop) {
				start = i;
				top = rowsHeight;
				break;
			}

			rowsHeight += rowHeight;
			i += 1;
		}

		while (i < items.length) {
			rowsHeight += heightMap[i] || averageHeight;
			i += 1;

			if (rowsHeight > scrollTop + viewportHeight) {
				break;
			}
		}

		end = i;

		averageHeight = Math.round(rowsHeight / end);
		updateRemainigHeight(averageHeight);
	}

	async function handleScroll() {
		updateVisibleItems();
		dispatch("scroll", { start, end });
	}

	onMount(() => {
		rows = contents.children as HTMLCollectionOf<HTMLElement>;
		isMounted = true;
	});

</script>

<div
	class="wx-virtual-list"
	bind:this={viewport}
	bind:offsetHeight={viewportHeight}
	on:scroll={handleScroll}
	use:scrollManager
	style="height: {height};">
	<div
		class="wx-content"
		bind:this={contents}
		style="padding-top: {top}px; padding-bottom: {bottom}px;">
		{#each visibleItems as row (row.index)}
			<div class="wx-item" data-id={row.data.id} data-index={row.index}>
				<slot name="item" item={row.data} />
			</div>
		{/each}
	</div>
	<slot name="extra" />
</div>

<style>
	.wx-virtual-list {
		position: relative;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		display: block;
	}

	.wx-content,
	.wx-item {
		display: block;
	}

	.wx-item {
		overflow: hidden;
	}

</style>
