<script lang="ts">
	import {
		getAreaId,
		getCardAreaId,
		isSameId,
		scrollManager,
	} from "@xbs/lib-kanban";
	import type { IApi, ICard, TID } from "@xbs/lib-kanban";

	import { onMount, tick } from "svelte";

	export let api: IApi;

	const {
		cardsMap: rCardsMap,
		columns: rColumns,
		rows: rRows,
		cardHeight: rCardHeight,
		cards: rCards,
		columnKey: rColumnKey,
		scroll: scrollTo,
	} = api.getReactiveState();

	$: cardsMap = $rCardsMap;
	$: columns = $rColumns;
	$: rows = $rRows;
	$: cardHeight = $rCardHeight;
	$: cards = $rCards;
	$: columnKey = $rColumnKey;

	const margins = 10;
	$: fullCardHeight = cardHeight + margins;
	$: heightReserve = fullCardHeight * 2;

	let listEl: HTMLElement;
	let maxCardsCount = 0;
	let byRow = {};
	let startIndex = 0;
	let endIndex = 1;

	$: {
		if (rows && columns && cardsMap) {
			createByRowConfig();
		}
	}
	$: {
		if ($scrollTo && $scrollTo.to === "card" && $scrollTo.id) {
			scrollToItem($scrollTo.id).then(ready => {
				if (ready) {
					$scrollTo = null;
				}
			});
		}
	}

	$: updateRows(startIndex, endIndex);

	$: topPadding = startIndex * fullCardHeight;
	$: bottomPadding = (maxCardsCount - endIndex) * fullCardHeight;

	onMount(() => {
		updateIndexes();
	});

	function createByRowConfig() {
		maxCardsCount = 0;

		rows.reduce((prev, row) => {
			if (row.collapsed) {
				return prev;
			}
			const maxInRow = columns.reduce((max, col) => {
				const areaId = getAreaId(col.id, row.id);
				const areaCardsCount = cardsMap[areaId].length;
				if (areaCardsCount > max) {
					max = areaCardsCount;
				}
				return max;
			}, 0);

			byRow[row.id] = {
				id: row.id,
				maxCardsCount: maxInRow,
				minIndex: prev,
				maxIndex: prev + maxInRow,

				startIndex: 0,
				endIndex: 0,
				visible: true,
			};
			maxCardsCount += maxInRow;

			return prev + maxInRow;
		}, 0);

		if (listEl) {
			startIndex = 0;
			endIndex = 0;
			updateIndexes();
		}
	}
	async function scrollToItem(id: TID) {
		const item = listEl.querySelector(`[data-drag-item="${id}"]`);
		if (item) {
			return;
		}

		const card = cards.find(card => isSameId(card.id, id));

		const columnId = getCardAreaId(card, columnKey);
		const topIndex = cardsMap[columnId].findIndex((c: ICard) =>
			isSameId(c.id, id)
		);
		const leftIndex = columns.findIndex(col => col.id === columnId);
		const columnWidth =
			parseFloat(
				getComputedStyle(listEl).getPropertyValue(
					"--wx-kanban-column-width"
				)
			) || 300;

		if (topIndex > -1) {
			await tick();

			listEl.scrollTop = topIndex * fullCardHeight;
			listEl.scrollLeft = columnWidth * leftIndex;

			return true;
		}

		return false;
	}

	function updateIndexes() {
		const scrollTop = listEl.scrollTop - heightReserve;

		const newStartIndex = Math.floor(
			scrollTop < 0 ? 0 : scrollTop / fullCardHeight
		);

		const newEndIndex =
			newStartIndex +
			Math.floor(
				(listEl.offsetHeight + heightReserve * 2) / fullCardHeight
			);

		if (newEndIndex >= maxCardsCount) {
			endIndex = maxCardsCount;
			return;
		}

		startIndex = newStartIndex;
		endIndex = newEndIndex;
	}

	function updateRows(startIndex: number, endIndex: number) {
		rows.forEach(row => {
			const byRowConfig = byRow[row.id];
			const d = endIndex - startIndex;

			let start = startIndex - byRowConfig.minIndex;
			let end = start + d;

			byRowConfig.startIndex = start < 0 ? 0 : start;
			byRowConfig.endIndex =
				end > byRowConfig.maxIndex ? byRowConfig.maxIndex : end;

			const visible = start < byRowConfig.maxIndex && end > 0;
			byRowConfig.visible = visible;

			if (row.collapsed) {
				byRowConfig.visible = true;
			}

			byRow[row.id] = byRowConfig;
		});
	}

	function handleScroll() {
		updateIndexes();
	}

	let virtualContentEl;

</script>

<div
	class="wx-list-wrapper"
	data-id="virtual-content"
	on:scroll={handleScroll}
	bind:this={listEl}
	use:scrollManager>
	<div
		bind:this={virtualContentEl}
		class="wx-content"
		style="padding-top:{topPadding}px;padding-bottom:{bottomPadding}px;">
		<slot {startIndex} {endIndex} {byRow} {virtualContentEl} />
	</div>
</div>

<style>
	.wx-list-wrapper {
		height: 100%;
		width: 100%;

		overflow-y: scroll;
		overflow-x: scroll;
	}
	.wx-content {
		display: flex;
		flex-direction: column;
		position: relative;
		width: fit-content;
	}

</style>
