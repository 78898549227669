<script lang="ts">
	import { getContext } from "svelte";
	import type { IApi } from "@xbs/lib-kanban";

	import IconButton from "./IconButton.svelte";
	export let api: IApi;

	const _ = getContext<any>("wx-i18n").getGroup("kanban");

	function handleRedo() {
		api.getStores().data.redo();
	}

	const { history: rHistory } = api.getReactiveState();

	$: canRedo = $rHistory.redo?.length > 0;

</script>

<IconButton
	click={handleRedo}
	label={_('Redo')}
	disabled={!canRedo}
	icon="wxi-redo" />
