<script lang="ts">
	import { isImage } from "@xbs/lib-kanban";
	import { Button } from "@xbs/svelte-wx";
	import Icon from "./Icon.svelte";

	import type { Writable } from "svelte/store";
	import type { IAttachment, TID } from "@xbs/lib-kanban";

	export let data: Writable<IAttachment[]>;

	const fileSize = ["b", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb"];

	function removeAll() {
		data.set([]);
	}

	function remove(id: TID) {
		data.update(v => v.filter(i => i.id !== id));
	}

	function formatSize(size: number) {
		let index = 0;
		while (size > 1024) {
			index++;
			size = size / 1024;
		}
		return Math.round(size * 100) / 100 + " " + fileSize[index];
	}
	function isPreview(obj: IAttachment) {
		const ext = obj?.url?.split(".").pop();
		const previewExt = obj?.previewURL?.split(".").pop();

		return isImage(previewExt) || isImage(ext);
	}

	function handleMakeCover(id: TID) {
		data.update(v => {
			return v.map(obj => {
				if (obj.id === id) {
					return { ...obj, isCover: true };
				} else {
					delete obj.isCover;
					return obj;
				}
			});
		});
	}
	function handleRemoveCover() {
		data.update(v => {
			return v.map(obj => {
				const copy = { ...obj };
				delete copy.isCover;
				return copy;
			});
		});
	}

</script>

{#if $data.length}
	<div class="wx-layout">
		<div class="wx-header">
			<Icon name="wxi-close" click={removeAll} />
		</div>
		<div class="wx-list">
			{#each $data as obj (obj.id)}
				<div class="wx-row">
					<div class="wx-file-icon">
						{#if isPreview(obj)}
							<div
								class="wx-thumb"
								style="background-image: url('{obj.previewURL || obj.url}')" />
						{:else}
							<Icon name="wxi-paperclip" size={20} />
						{/if}
					</div>
					<div class="wx-name">{obj.name}</div>
					{#if obj.file}
						<div class="wx-size">{formatSize(obj.file.size)}</div>
					{/if}
					<div class="wx-controls">
						{#if obj.status === 'client'}
							<Icon name="wxi-loading" spin />
						{:else if obj.status === 'error'}
							<Icon name="wxi-alert" />
							<Icon
								name="wxi-delete-outline"
								click={() => remove(obj.id)} />
						{:else if !obj.status || obj.status === 'server'}
							<div class="wx-hidden">
								<a
									class="wx-upload-link"
									href={obj.url}
									download={obj.name}
									target="_blank"
									rel="noreferrer nofollow noopener">
									<Icon name="wxi-external" clickable />
								</a>
								<Icon
									name="wxi-delete-outline"
									click={() => remove(obj.id)} />

								{#if isPreview(obj)}
									{#if !obj.isCover}
										<Button
											click={() => handleMakeCover(obj.id)}>
											Make cover
										</Button>
									{:else}
										<Button click={handleRemoveCover}>
											Remove cover
										</Button>
									{/if}
								{/if}
							</div>
						{/if}
					</div>
				</div>
			{/each}
		</div>
	</div>
{/if}

<style>
	.wx-layout {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.wx-header {
		padding: 10px;
		border-bottom: var(--wx-border);
		text-align: right;
	}

	.wx-list {
		overflow: auto;
	}

	.wx-row {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 10px;
		border-bottom: var(--wx-border);
	}

	.wx-name {
		flex: 1;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.wx-controls {
		display: flex;
	}
	.wx-hidden {
		display: none;
	}
	.wx-row:hover .wx-hidden {
		display: flex;
	}
	.wx-file-icon {
		min-width: 40px;
		min-height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.wx-thumb {
		width: 40px;
		height: 40px;
		max-width: 40px;
		max-height: 40px;

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.wx-upload-link {
		text-decoration: none;
		display: flex;
	}

</style>
