import { locateID } from "@xbs/lib-core-dom";

export function getListHandlers() {
	let navIndex = null;
	let isVisible = false;

	let list, options, navCallback, selectCallback;
	const init = (l, o, n, c) => {
		list = l;
		options = o;
		navCallback = n;
		selectCallback = c;
	};

	const setNav = index => {
		navIndex = index;
		isVisible = navIndex !== null;
		navCallback(navIndex);
	};
	const navigate = (dir, ev) => {
		const index =
			dir === null
				? null
				: Math.max(0, Math.min(navIndex + dir, options.length - 1));
		if (index === navIndex) return;
		setNav(index);

		if (navIndex && list) {
			const next = list.querySelectorAll(`.list > .item`)[navIndex];
			if (next) {
				next.scrollIntoView({ block: "nearest" });
				if (ev) ev.preventDefault();
			}
		}
	};

	const move = ev => {
		const id = locateID(ev);
		const index = options.findIndex(a => a.id === id);

		if (index !== navIndex) {
			setNav(index);
		}
	};

	const keydown = ev => {
		switch (ev.code) {
			case "Enter":
				isVisible ? selectCallback() : setNav(0);
				break;

			case "Space":
				isVisible ? null : setNav(0);
				break;

			case "Escape":
				navCallback((navIndex = null));
				break;

			case "Tab":
				navCallback((navIndex = null));
				break;

			case "ArrowDown":
				isVisible ? navigate(1, ev) : setNav(0);
				break;

			case "ArrowUp":
				isVisible ? navigate(-1, ev) : setNav(0);
				break;

			default:
				break;
		}
	};

	return { move, keydown, init, navigate };
}
