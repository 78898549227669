<script lang="ts">
	import type { SvelteComponent } from "svelte";
	import type {
		ICardMeta,
		ICardShape,
		INormalizedCard,
	} from "@xbs/lib-kanban";

	export let cardFields: INormalizedCard;
	export let dragging = false;
	export let selected = false;

	export let cardShape: ICardShape;
	export let cardTemplate: SvelteComponent;
	export let meta: ICardMeta = null;
	export let cardHeight: number | null;
	export let menu: boolean;
	export let api;

	$: cardHeightValue = cardHeight ? cardHeight + "px" : "auto";

</script>

<div
	class="wx-card"
	class:wx-hidden={dragging}
	class:wx-selected={selected}
	class:wx-dimmed={meta?.dimmed}
	data-drag-item={cardFields.id}
	style="height:{cardHeightValue};max-height:{cardHeightValue};"
	data-id={cardFields.id}>
	<svelte:component
		this={cardTemplate}
		{api}
		{cardFields}
		{dragging}
		{selected}
		{cardShape}
		{menu}
		on:action />
</div>

<style>
	.wx-card {
		box-sizing: border-box;

		background: var(--wx-kanban-content-background);
		border: var(--wx-kanban-card-border);
		border-radius: var(--wx-kanban-card-border-radius);
		box-shadow: var(--wx-kanban-shadow);

		width: 100%;
		min-height: 20px;
		margin: 12px 0;

		position: relative;
		font-size: var(--wx-font-size);

		display: flex;
		flex-direction: column;

		cursor: pointer;
	}
	.wx-dimmed {
		opacity: 0.2;
	}
	.wx-hidden {
		display: none;
	}

	.wx-selected {
		border: 1px solid var(--wx-color-primary);
	}

</style>
