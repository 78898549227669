<script lang="ts">
	import { customIcons } from "@xbs/lib-kanban";
	import type { TWxIcons } from "@xbs/lib-kanban";

	export let name: TWxIcons | keyof typeof customIcons;
	export let size = 20;
	export let spin = false;
	export let click = null;
	export let clickable = !!click;

</script>

{#if customIcons[name]}
	<i
		class="wx-icon"
		class:wx-spin={spin}
		class:wx-clickable={clickable}
		style="font-size:{size}px;"
		on:click={click}>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={size}
			height={size}
			fill="currentColor">
			<path d={customIcons[name].path} />
		</svg>
	</i>
{:else}
	<i
		class="wx-icon {name}"
		class:wx-spin={spin}
		class:wx-clickable={clickable}
		style="font-size:{size}px;"
		on:click={click} />
{/if}

<style>
	.wx-icon {
		color: var(--wx-color-font-alt);

		display: flex;
		align-items: center;
	}
	.wx-clickable {
		cursor: pointer;
	}
	.wx-clickable:hover {
		background-color: var(--wx-background-hover);
	}

	@keyframes wx-spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
	.wx-spin {
		-webkit-animation: wx-spin 2s linear infinite;
		animation: wx-spin 2s linear infinite;
	}

</style>
