<script lang="ts">
	import type { IApi, IToolbarItem } from "@xbs/lib-kanban";

	import {
		Toolbar,
		Search,
		AddColumn,
		AddRow,
		Sort,
		Spacer,
		Undo,
		Redo,
	} from "@xbs/svelte-kanban";
	import Template from "./Template.svelte";

	export let api: IApi;
	export let items: IToolbarItem[] = [];

</script>

<Toolbar {api}>
	{#each items as item}
		{#if item.type === 'search'}
			<Search {api} options={item.options} />
		{:else if item.type === 'undo'}
			<Undo {api} />
		{:else if item.type === 'redo'}
			<Redo {api} />
		{:else if item.type === 'spacer'}
			<Spacer />
		{:else if item.type === 'sort'}
			<Sort {api} options={item.options} />
		{:else if item.type === 'addColumn'}
			<AddColumn {api} />
		{:else if item.type === 'addRow'}
			<AddRow {api} />
		{:else if item.type === 'template' && item.template}
			<Template template={item.template} />
		{:else if item}
			<Template template={item.type} />
		{/if}
	{/each}
</Toolbar>
