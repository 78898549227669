export default {
	kanban: {
		Save: "Save",
		Close: "Close",
		Delete: "Delete",
		Name: "Name",
		Description: "Description",
		Type: "Type",
		"Start date": "Start date",
		"End date": "End date",

		Result: "Result",
		"No results": "No results",
		Search: "Search",
		"Search in": "Search in",
		"Add new row": "Add new row",
		"Add new column": "Add new column",
		"Add new card": "Add new card",
		"Edit card": "Edit card",
		Edit: "Edit",

		Everywhere: "Everywhere",
		Label: "Label",
		Status: "Status",
		Color: "Color",
		Date: "Date",
		Priority: "Priority",
		Progress: "Progress",
		Users: "Users",

		Untitled: "Untitled",
		Rename: "Rename",
		"Move up": "Move up",
		"Move down": "Move down",
		"Move left": "Move left",
		"Move right": "Move right",
		Sort: "Sort",
		"Label (a-z)": "Label (a-z)",
		"Label (z-a)": "Label (z-a)",
		"Description (a-z)": "Description (a-z)",
		"Description (z-a)": "Description (z-a)",

		Duplicate: "Duplicate",
		"Duplicate of": "Duplicate of",

		"Relates to": "Relates to",
		"Depends on": "Depends on",
		"Is required for": "Is required for",
		Duplicates: "Duplicates",
		"Is duplicated by": "Is duplicated by",
		"Parent for": "Parent for",
		"SubTask of": "SubTask of",

		Cancel: "Cancel",
		"Link task": "Link task",
		"Select a relation": "Select a relation",
		"Select a task": "Select a task",

		"Would you like to delete this comment?":
			"Would you like to delete this comment?",
		"No comments yet": "No comments yet",
		"Would you like to delete this card?":
			"Would you like to delete this card?",
	},
};
