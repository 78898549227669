<script lang="ts">
	import { afterUpdate, createEventDispatcher, onDestroy } from "svelte";
	import { portal } from "@xbs/lib-kanban";
	import type {
		IApi,
		ICard,
		IColumn,
		IColumnShape,
		TAreasMeta,
		TEventDispatcher,
	} from "@xbs/lib-kanban";

	import Icon from "./../lib/Icon.svelte";

	export let api: IApi;
	export let contentEl: HTMLElement;
	export let column: IColumn;
	export let areasMeta: TAreasMeta;
	export let isMenuVisible: boolean;
	export let renaming = false;

	const dispatch: TEventDispatcher = createEventDispatcher();

	const { readonly, columnShape, cardsMap } = api.getReactiveState();
	$: edit = $readonly.edit;

	let columnLabel = null;
	let columnEl: HTMLElement;

	function endRenaming() {
		if (renaming && columnLabel?.trim()) {
			dispatch("action", {
				action: "update-column",
				data: {
					id: column.id,
					column: {
						label: columnLabel,
					},
				},
			});
		}
		renaming = false;
		columnLabel = null;
		dispatch("action", { action: "close-column-input", data: {} });
	}

	function handleColumnRename() {
		if (!edit) {
			return;
		}
		renaming = true;
	}

	function handleColumnInput(e: any) {
		columnLabel = e.target.value;
	}

	function handleInputKeypress(e: any) {
		if (e.charCode === 13) {
			endRenaming();
		}
	}

	function focus(node: HTMLElement) {
		node.focus();
	}

	function handleColumnCollapse() {
		dispatch("action", {
			action: "update-column",
			data: {
				id: column.id,
				column: {
					collapsed: !column.collapsed,
				},
			},
		});
	}

	let columnOffset = null;
	function positionCollapsedCol() {
		columnOffset = columnEl?.offsetLeft;
	}

	afterUpdate(() => {
		positionCollapsedCol();
	});

	let isValid;
	if (process.env.TRIALPACKAGE) {
		isValid = () => {
			if (typeof window === "undefined") return true;

			const l = location.hostname;
			const t = [
				// dhtmlx.com
				"ZGh0bWx4LmNvbQ==",
				// dhtmlxcode.com
				"ZGh0bWx4Y29kZS5jb20=",
				// webixcode.com
				"d2ViaXhjb2RlLmNvbQ==",
				// webix.io
				"d2ViaXguaW8=",
				// repl.co
				"cmVwbC5jbw==",
			];

			for (let i = 0; i < t.length; i++) {
				const x = window.atob(t[i]);
				if (x === l || l.endsWith("." + x)) return true;
			}

			return false;
		};
	}

	function buildColumnCss(
		columnShape: IColumnShape,
		column: IColumn,
		isOverLimit: boolean,
		cards: ICard[]
	) {
		let columnClass = "wx-column";
		if (column.collapsed) columnClass += " wx-collapsed";
		if (isOverLimit) columnClass += " wx-over-limit";
		if (column.css) columnClass += " " + column.css;

		if (columnShape && columnShape.css)
			columnClass += " " + columnShape.css(column, cards);
		return columnClass;
	}

	$: columnClass = buildColumnCss(
		$columnShape,
		column,
		areasMeta[column.id].isOverLimit,
		$cardsMap[column.id]
	);

	const ro = new ResizeObserver(() => {
		positionCollapsedCol();
	});
	$: if (contentEl) ro.observe(contentEl);

	onDestroy(() => {
		ro.disconnect();
	});

</script>

<div bind:this={columnEl} class={columnClass} data-column-header={column.id}>
	{#if column.collapsed && columnEl && contentEl}
		<div
			class="wx-collapsed-column{column.css ? ' ' + column.css : ''}"
			style="left:{columnOffset}px;"
			on:click={() => handleColumnCollapse()}
			use:portal={{ container: contentEl }} />
	{/if}
	<div class="wx-collapse-icon">
		<Icon
			name={column.collapsed ? 'wxi-angle-right' : 'wxi-angle-left'}
			size={24}
			clickable
			click={() => handleColumnCollapse()} />
	</div>

	<div class="wx-label" on:dblclick={() => handleColumnRename()}>
		{#if renaming}
			<input
				type="text"
				class="wx-input"
				value={column.label}
				on:input={handleColumnInput}
				on:keypress={handleInputKeypress}
				on:blur={endRenaming}
				use:focus />
		{:else}{column.label}{/if}
		{#if isMenuVisible && edit && !renaming}
			{#if column.limit}
				({areasMeta[column.id].cardsCount}/{areasMeta[column.id].totalLimit})
			{/if}
			<div class="wx-menu" data-menu-id={column.id}>
				<Icon name="wxi-dots-h" clickable />
			</div>
		{/if}
	</div>

	{#if process.env.TRIALPACKAGE && !isValid()}
		<span
			class="wx-mark"
			class:wx-error={process.env.TRIALDATE < new Date()}>Trial</span>
	{/if}
</div>

<style>
	.wx-column {
		min-width: var(--wx-kanban-column-width);
		width: var(--wx-kanban-column-width);
		margin-left: 12px;

		background: var(--wx-kanban-content-background);
		border: var(--wx-kanban-card-border);
		border-radius: var(--wx-kanban-header-border-radius);
		padding: 0 var(--wx-padding);
		display: flex;
		align-items: center;
		position: relative;
		z-index: 10;
		box-shadow: var(--wx-kanban-shadow);
	}
	.wx-column:last-child {
		margin-right: 12px;
	}
	.wx-over-limit {
		border: solid var(--wx-kanban-over-limit-color) 1px;
	}
	.wx-collapsed {
		max-width: var(--wx-kanban-collapsed-column-width);
		width: var(--wx-kanban-collapsed-column-width);
		min-width: var(--wx-kanban-collapsed-column-width);
		overflow: hidden;

		display: flex;
		align-items: center;
		justify-content: center;
	}
	.wx-column.wx-collapsed .wx-label {
		display: none;
	}
	.wx-column.wx-collapsed .wx-menu {
		display: none;
	}
	.wx-collapse-icon:hover {
		background: var(--wx-background);
		cursor: pointer;
	}
	.wx-label {
		display: flex;
		align-items: center;

		font-weight: var(--wx-font-weight-md);
		font-size: var(--wx-font-size);
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		height: 100%;
		margin-left: 4px;
	}
	.wx-input {
		padding: var(--wx-input-padding);
		outline: none;
		flex: 1;
		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		color: var(--wx-color-font);
		width: 100%;
		box-sizing: border-box;
		border: var(--wx-input-border);
		border-radius: var(--wx-input-border-radius);
		background-color: transparent;
		font-weight: var(--wx-font-weight-md);
	}
	.wx-input:focus {
		border: 1px solid var(--wx-color-primary);
	}

	.wx-mark {
		position: absolute;
		right: 34px;
		top: 12px;
		transform: rotate(30deg);
		color: #ccc;
		font-weight: 400;
		text-transform: uppercase;
	}
	.wx-mark.wx-error {
		color: var(--wx-color-danger);
	}
	.wx-menu {
		position: absolute;
		top: calc(50% - 10px);
		right: 10px;
		cursor: pointer;
	}
	.wx-menu:hover {
		background: var(--wx-background);
	}
	.wx-collapsed-column {
		position: absolute;
		top: 50px;
		border-radius: 4px;
		width: var(--wx-kanban-collapsed-column-width);
		height: calc(100% - 50px);
		cursor: pointer;
		transition: background 0.2s ease;
	}
	.wx-collapsed-column:hover {
		background: var(--wx-kanban-collapsed-background-hover);
		box-shadow: var(--wx-kanban-shadow);
	}

</style>
