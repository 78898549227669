<script lang="ts">
	import { uid } from "@xbs/lib-core-dom";

	import DateRangePicker from "./CustomDateRangePicker.svelte";

	export let start: Date;
	export let end: Date;
	export let format: string;
	export let id: number = uid();

</script>

<DateRangePicker bind:start bind:end {id} {format} />
