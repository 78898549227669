<script lang="ts">
	import { getContext } from "svelte";
	import { uid, dateToString } from "@xbs/lib-core-dom";
	import {
		Text,
		Dropdown,
		Button,
		RangeCalendar,
		Portal,
	} from "@xbs/svelte-wx";
	import Icon from "./Icon.svelte";

	export let start = null;
	export let end = null;

	export let id: number = uid();
	export let format: string;

	const _ = getContext<any>("wx-i18n").getGroup("calendar");

	let popup: boolean;
	let dateFormat =
		typeof format === "function" ? format : dateToString(format, _);

	function cancel(e: any) {
		e.stopPropagation();
		popup = null;
	}

	function setToday() {
		const date = new Date();
		start = date;
		end = date;
	}
	function clearDate() {
		start = null;
		end = null;
	}

	let formattedValue = "";
	$: {
		if (start) {
			formattedValue =
				dateFormat(start) + (end ? ` - ${dateFormat(end)}` : "");
		} else {
			formattedValue = "";
		}
	}

	function showPopup() {
		popup = true;
	}
	let layoutEl: HTMLElement;

</script>

<svelte:window on:scroll={cancel} />

<div class="wx-layout" on:click={showPopup} bind:this={layoutEl}>
	<Text
		value={formattedValue}
		{id}
		readonly={true}
		inputStyle={'cursor: pointer; text-overflow: ellipsis; padding-right: 18px;'} />
	<div class="wx-input-icon">
		<Icon name="wxi-calendar" />
	</div>

	{#if popup}
		<Portal target={layoutEl}>
			<Dropdown {cancel} width={'unset'}>
				<RangeCalendar bind:start bind:end buttons={false} />
				<div class="wx-buttons">
					<Button type="link wx-calendar-btn" click={clearDate}>
						Clear
					</Button>
					<Button type="link wx-calendar-btn" click={setToday}>
						Today
					</Button>
					<Button type="primary wx-calendar-btn" click={cancel}>
						Done
					</Button>
				</div>
			</Dropdown>
		</Portal>
	{/if}
</div>

<style>
	.wx-layout {
		position: relative;
		width: 100%;
	}

	.wx-buttons {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: var(--wx-calendar-padding) 0;
	}
	:global(.wx-calendar-btn:not(:last-child)) {
		margin-right: 12px;
	}
	.wx-input-icon {
		position: absolute;
		right: var(--wx-input-icon-indent);
		top: 50%;
		transform: translateY(-50%);
	}

</style>
