<script lang="ts">
	import {
		Button,
		Dropdown,
		Text,
		Area,
		Field,
		Select,
		Combo,
		DatePicker,
		ColorPicker,
		Slider,
		Locale,
	} from "@xbs/svelte-wx";

	import {
		elementsIds,
		defaultEditorConfig,
		isSameId,
		normalizeEditorShape,
		type TID,
	} from "@xbs/lib-kanban";

	import type {
		INormalizedCard,
		TLocaleGroup,
		TEditorShape,
		IEditConfig,
		ICardShape,
	} from "@xbs/lib-kanban";

	import { form } from "@xbs/lib-svelte";
	import en from "../en";
	import { en as coreEn } from "@xbs/wx-core-locales";

	import FilesControl from "../lib/FilesControl.svelte";
	import UserIcon from "../lib/UserIcon.svelte";
	import DateRangePicker from "../lib/DateRangePicker.svelte";
	import MultiCombo from "../lib/Multicombo.svelte";
	import InlineLocale from "../lib/InlineLocale.svelte";
	import Icon from "../lib/Icon.svelte";
	import Comments from "./Comments.svelte";
	import Links from "./Links.svelte";

	import type { ICard, IApi, IKanbanEditorConfig } from "@xbs/lib-kanban";
	import type { Writable } from "svelte/store";

	export let config: IKanbanEditorConfig = defaultEditorConfig;
	export let shape: TEditorShape[] = null;
	export let api: IApi | undefined;

	$: {
		config = { ...defaultEditorConfig, ...config };
	}

	let editCard = null;
	let edit: Writable<IEditConfig> = null;
	let cardShape: Writable<ICardShape> = null;
	let editorShape: TEditorShape[] = null;
	let cards: Writable<INormalizedCard[]> = null;
	let dragItemId: Writable<TID> = null;
	let currentUser: Writable<TID> = null;
	$: {
		if (api) {
			if (!edit && !cardShape) {
				const rState = api.getReactiveState();
				edit = rState.edit;
				cardShape = rState.cardShape;
				cards = rState.cards;
				dragItemId = rState.dragItemId;
				currentUser = rState.currentUser;
			}
		}
	}

	$: editCard =
		!$dragItemId &&
		cards &&
		$cards.find(c => isSameId(c.id, $edit?.cardId));
	$: editorShape = cardShape && normalizeEditorShape(shape, $cardShape);

	let formValues: ICard;
	function saveForm() {
		if (formValues) {
			api.exec("update-card", {
				card: { ...formValues },
				id: formValues.id,
			});
		}
	}
	const values = form(
		{},
		val => {
			formValues = val;
			if (config.autoSave) {
				saveForm();
			}
		},
		{ debounce: config.debounce }
	);
	$: editorShape && values.reset(setDefaults(editCard));

	function setDefaults(obj: any) {
		const copy = { ...obj };
		editorShape.forEach(f => {
			if (typeof copy[f.key] === "undefined") {
				if (typeof copy[f.key] === "undefined") {
					if (f.type === "files") {
						copy[f.key] = [];
					} else if (f.type === "date") {
						copy[f.key] = null;
					} else if (f.type === "progress") {
						copy[f.key] = 0;
					} else {
						copy[f.key] = "";
					}
				}
			}
		});
		return copy;
	}
	function handleClose() {
		api.exec("set-edit", null);
	}
	let _: TLocaleGroup;

	let view = "main";
	function handleViewChange(v: string) {
		view = v;
	}

	let votersList = false;

	function handleVote() {
		const cardId = editCard.id;
		if (editCard.votes?.includes($currentUser))
			api.exec("delete-vote", { cardId });
		else api.exec("add-vote", { cardId });
	}

	let users = [];
	let voters = [];
	$: {
		if (api) {
			const userConfig = api.getState().cardShape.users;
			if (userConfig?.values?.length) {
				users = userConfig.values;
				if ($values?.votes?.length) {
					voters = $values.votes.map(v => users.find(u => u.id == v));
				} else {
					voters = [];
				}
			}
		}
	}

</script>

<Locale words={{ ...coreEn, ...en }} optional>
	<InlineLocale bind:_ />
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div
		class="wx-editor"
		class:wx-editor-open={editCard}
		data-kanban-id={elementsIds.editor}
		on:click|stopPropagation>
		{#if _ && editCard}
			<div class="wx-editor-controls-wrapper">
				{#if view === 'main' && !config.autoSave}
					<Button type="primary wx-editor-btn" click={saveForm}>
						{_('Save')}
					</Button>
				{/if}
				<div class="wx-editor-controls">
					{#if view === 'main'}
						{#if $cardShape.votes?.show}
							<div class="wx-kanban-editor-voting">
								<div
									class="wx-kanban-editor-vote"
									class:wx-kanban-editor-voted={editCard.votes?.includes($currentUser)}
									on:mouseenter={() => (votersList = true)}
									on:mouseleave={() => (votersList = false)}>
									<Button click={handleVote}>
										<Icon name="wxi-like" />
										{editCard.votes?.length || 0}
									</Button>
								</div>

								{#if votersList && voters?.length}
									<Dropdown
										width="230px"
										cancel={() => (votersList = false)}>
										<div class="wx-kanban-voters-list">
											{#each voters as user (user.id)}
												<div
													class="wx-multiselect-option">
													<UserIcon
														size={'small'}
														data={user} />
													<span
														class="wx-multiselect-label">
														{user.label}
													</span>
												</div>
											{/each}
										</div>
									</Dropdown>
								{/if}
							</div>
						{/if}

						<Icon name="wxi-close" click={handleClose} />
					{:else}
						<Button click={() => handleViewChange('main')}>
							{_('Back')}
						</Button>
					{/if}
				</div>
			</div>
			{#if view === 'main'}
				<div class="wx-kanban-editor-main">
					{#each editorShape as field (field.id)}
						{#if field.type === 'text'}
							<Field label={_(field.label)} position="top" let:id>
								<Text
									{id}
									bind:value={$values[field.key]}
									focus
									{...field.config} />
							</Field>
						{:else if field.type === 'textarea'}
							<Field label={_(field.label)} position="top" let:id>
								<Area
									{id}
									bind:value={$values[field.key]}
									{...field.config} />
							</Field>
						{:else if field.type === 'progress'}
							<Field
								label={`${_(field.label)} ${$values[field.key]}%`}
								position="top"
								let:id>
								<Slider
									{id}
									bind:value={$values[field.key]}
									min={0}
									{...field.config} />
							</Field>
						{:else if field.type === 'combo'}
							<Field label={_(field.label)} position="top" let:id>
								<Combo
									{id}
									options={field.values}
									bind:value={$values[field.key]}
									let:option
									{...field.config}>
									<div class="wx-combo-option">
										{#if option.color}
											<div
												class="wx-color"
												style="background:{option.color}" />
										{:else if option.avatar || option.avatarColor}
											<UserIcon data={option} />
										{/if}
										{option.label}
									</div>
								</Combo>
							</Field>
						{:else if field.type === 'select'}
							<Field label={_(field.label)} position="top" let:id>
								<Select
									{id}
									bind:value={$values[field.key]}
									options={field.values}
									{...field.config} />
							</Field>
						{:else if field.type === 'color'}
							<Field label={_(field.label)} position="top" let:id>
								<ColorPicker
									{id}
									bind:value={$values[field.key]}
									colors={field.values}
									{...field.config} />
							</Field>
						{:else if field.type === 'multiselect'}
							<Field label={_(field.label)} position="top">
								<MultiCombo
									bind:values={$values[field.key]}
									checkboxes
									options={field.values}
									let:option
									{...field.config}>
									<div class="wx-multiselect-option">
										<UserIcon data={option} />
										<span class="wx-multiselect-label">
											{option.label}
										</span>
									</div>
								</MultiCombo>
							</Field>
						{:else if field.type === 'date'}
							<Field label={_(field.label)} position="top" let:id>
								<DatePicker
									{id}
									format={field.format || '%m/%d/%Y'}
									bind:value={$values[field.key]}
									{...field.config} />
							</Field>
						{:else if field.type === 'dateRange'}
							<Field label={_(field.label)} position="top" let:id>
								<DateRangePicker
									{id}
									format={field.format || '%m/%d/%Y'}
									bind:start={$values[field.key.start]}
									bind:end={$values[field.key.end]} />
							</Field>
						{:else if field.type === 'files'}
							<div class="files">
								<Field label={_(field.label)} position="top">
									<FilesControl {field} {values} />
								</Field>
							</div>
						{:else if field.type === 'links'}
							<Field label={_(field.label)} position="top">
								<Links card={editCard} {api} />
							</Field>
						{:else if field.type === 'comments' && users.length}
							<div class="wx-card-comments">
								<Field label={_(field.label)} position="top">
									{#if field.config?.placement === 'editor'}
										<Comments
											card={editCard}
											{api}
											{users}
											shape={field} />
									{:else}
										<Button
											type="primary block"
											click={() => handleViewChange('comments')}>
											{_('Show comments')}
											({editCard.comments?.length || 0})
										</Button>
									{/if}
								</Field>
							</div>
						{/if}
					{/each}
				</div>
			{:else if view === 'comments'}
				<Comments
					card={editCard}
					{api}
					{users}
					shape={editorShape.find(field => field.type === 'comments')} />
			{/if}
		{/if}
	</div>
</Locale>

<style>
	.wx-editor {
		background: var(--wx-kanban-editor-background);
		padding: var(--wx-modal-padding);
		width: 100%;
		min-width: 100%;
		height: var(--wx-kanban-editor-height);
	}

	.wx-editor-controls-wrapper {
		display: flex;
		margin-bottom: 22px;
	}

	.wx-editor-controls {
		display: flex;
		gap: 12px;
		justify-content: flex-end;
		width: 100%;
	}

	.wx-editor-controls-wrapper :global(.wx-editor-btn) {
		flex: 0 0 auto;
	}

	.wx-multiselect-option {
		display: flex;
		align-items: center;
	}
	.wx-kanban-editor-voting .wx-multiselect-option:not(:last-child) {
		margin-bottom: 12px;
	}

	.wx-multiselect-label {
		padding: 0 5px;
	}
	.wx-combo-option {
		display: flex;
		align-items: center;
		gap: 12px;
	}
	.wx-color {
		border-radius: 50%;
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	.wx-card-comments {
		width: calc(100% + var(--wx-kanban-editor-x-padding) * 2);
		padding: var(--wx-padding) var(--wx-kanban-editor-x-padding);
		margin: 0 calc(-1 * var(--wx-kanban-editor-x-padding));
		background: var(--wx-kanban-background);
	}

	.wx-kanban-editor-voting {
		position: relative;
	}

	.wx-kanban-editor-vote :global(button) {
		display: flex;
		gap: 4px;
		padding: 5px;
	}

	.wx-kanban-editor-vote.wx-kanban-editor-voted :global(button i) {
		color: var(--wx-color-primary);
	}

	.wx-kanban-voters-list {
		padding: var(--wx-padding);
	}

	/* [todo] create similar functionality in svelte-ws */
	:global(.wx-editor-controls .wx-list-item) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 5px 0;
	}
	:global(.wx-editor-controls .wx-hover) {
		cursor: pointer;
	}
	:global(.wx-kanban > .wx-editor label) {
		padding-left: 0;
	}
	.files :global(.field-control .label) {
		display: block;
	}

	.files :global(.field-control .dropzone) {
		height: 68px;
	}

</style>
