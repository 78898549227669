<script>
	import { Willow } from "@xbs/svelte-wx";

	export let fonts = true;
	const SLOTS = $$slots;

</script>

{#if SLOTS && SLOTS.default}
	<Willow {fonts}>
		<slot />
	</Willow>
{:else}
	<Willow {fonts} />
{/if}

<style global>
	.wx-willow-theme * {
		box-sizing: border-box;
	}
	.wx-willow-theme {
		--wx-field-width: 100%;
		--wx-theme-name: willow;

		/* Kanban */
		--wx-kanban-background: var(--wx-background-alt);

		--wx-kanban-column-width: 290px;
		--wx-kanban-column-height: 300px;

		--wx-kanban-toolbar-height: 56px;
		--wx-kanban-toolbar-align: center;
		--wx-kanban-toolbar-justify: flex-start;
		--wx-kanban-toolbar-control-hover: rgba(0, 0, 0, 0.07);
		--wx-kanban-toolbar-control-active: rgba(0, 0, 0, 0.15);
		--wx-kanban-toolbar-border: none;

		--wx-kanban-card-field-padding: 12px;
		--wx-kanban-content-background: var(--wx-background);
		--wx-kanban-card-border: 1px solid rgba(44, 47, 60, 0.12);
		--wx-kanban-card-border-radius: 3px;
		--wx-kanban-header-border-radius: 6px;

		--wx-kanban-row-line: none;

		--wx-kanban-user-icon-size: 36px;

		--wx-kanban-header-height: 64px;
		--wx-kanban-editor-width: 569px;
		--wx-kanban-editor-modal-width: 1000px;
		--wx-kanban-editor-height: auto;
		--wx-kanban-editor-x-padding: 20px;
		--wx-kanban-editor-background: var(--wx-background);
		--wx-kanban-editor-top-border: var(--wx-border);

		--wx-kanban-over-limit-color: var(--wx-color-danger);
		--wx-kanban-collapsed-column-width: 44px;
		--wx-kanban-z-index: 1;

		--wx-progress-height: 4px;
		--wx-kanban-progress-inactive-color: var(--wx-background-hover);

		--wx-kanban-menu-min-width: 122px;

		--wx-kanban-shadow: 0px 1px 2px rgba(44, 47, 60, 0.06),
			0px 3px 10px rgba(44, 47, 60, 0.12);
		--wx-kanban-box-border: none;

		--wx-kanban-collapsed-padding: 21px;
		--wx-kanban-collapsed-margin: 12px;
		--wx-kanban-collapsed-background: var(--wx-background);
		--wx-kanban-collapsed-background-hover: #e6e6e6;
		/* End Kanban */
	}

	.wx-styled-scroll {
		--wx-styled-scroll-track-color: #fafafa;
		--wx-styled-scroll-thumb-color: #babac0;
		--wx-styled-scroll-width: 16px;
	}
	.wx-styled-scroll * {
		scrollbar-width: thin;
	}
	.wx-styled-scroll *::-webkit-scrollbar {
		width: var(--wx-styled-scroll-width);
	}
	.wx-styled-scroll *::-webkit-scrollbar-track {
		background-color: var(--wx-styled-scroll-track-color);
	}
	.wx-styled-scroll *::-webkit-scrollbar-thumb {
		background-color: var(--wx-styled-scroll-thumb-color);
		border-radius: var(--wx-styled-scroll-width);
		border: 4px solid var(--wx-styled-scroll-track-color);
	}
	.wx-styled-scroll *::-webkit-scrollbar-button {
		display: none;
	}

</style>
