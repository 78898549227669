<script lang="ts">
	import {
		elementsIds,
		defaultEditorConfig,
		type TEditorShape,
	} from "@xbs/lib-kanban";
	import type { IApi, IKanbanEditorConfig } from "@xbs/lib-kanban";
	import Editor from "./Editor.svelte";

	export let config: IKanbanEditorConfig = defaultEditorConfig;
	export let api: IApi;
	export let shape: TEditorShape[];

	const { edit } = api.getReactiveState();

	$: editCard = api.getCard($edit?.cardId);

	$: {
		config = { ...defaultEditorConfig, ...config };
	}

</script>

<div
	class="wx-sidebar"
	class:wx-sidebar-open={!!editCard}
	data-kanban-id={elementsIds.editor}
	on:click|stopPropagation>
	<Editor {api} {config} {shape} />
</div>

<style>
	.wx-sidebar {
		background: var(--wx-kanban-editor-background);
		width: 0;
		min-width: 0;
		height: auto;
		transition: width 0.1s, min-width 0.1s;
		overflow-y: auto;
		overflow-x: hidden;
		z-index: 999999;
	}
	.wx-sidebar-open {
		width: var(--wx-kanban-editor-width);
		min-width: var(--wx-kanban-editor-width);
		box-shadow: var(--wx-kanban-shadow);
		border-left: var(--wx-kanban-box-border);
		border-top: var(--wx-kanban-editor-top-border);
	}

</style>
