<script lang="ts">
	import Icon from "../../lib/Icon.svelte";
	import UserIcon from "../../lib/UserIcon.svelte";

	import type { ICardShape, INormalizedCard, TID } from "@xbs/lib-kanban";

	import { dateToString } from "@xbs/lib-core-dom";
	import { getContext } from "svelte";
	import type { Writable } from "svelte/store";

	export let cardFields: INormalizedCard;
	export let cardShape: ICardShape;
	export let api;

	type TFooterValues = {
		users?: ICardShape["users"]["values"];
		endDate?: string;
		startDate?: string;
		attached?: number;
		comments?: number;
		votes?: number;
	};

	const lang = getContext<any>("wx-i18n");
	const defaultFormat = "%M %d";

	function getFooterValues(
		cardFields: INormalizedCard,
		shape: ICardShape
	): TFooterValues {
		let values = {} as TFooterValues;

		const { show } = shape?.users || {};
		const usersIds = cardFields.users;

		if (show && usersIds) {
			const usersConfig = (
				Array.isArray(usersIds) ? usersIds : [usersIds]
			).reduce((c, id) => {
				const user = shape.users.values?.find(v => v.id === id);
				if (user) {
					c.push(user);
				}
				return c;
			}, []) as ICardShape["users"]["values"];

			let users = usersConfig.map(user => ({
				...user,
				label: user.label || "",
			}));
			const maxUsersCount = 2;
			if (usersConfig.length > maxUsersCount) {
				users = users.splice(0, maxUsersCount);
				users.push({
					label: `+${usersConfig.length - users.length}`,
					id: "$total",
				});
			}
			if (users?.length) {
				values.users = users;
			}
		}
		const { show: showStartDate, format: startFormat } =
			shape.start_date || {};
		const { show: showEndDate, format: endFormat } = shape.end_date || {};

		let { end_date, start_date } = cardFields;

		if (showStartDate || showEndDate) {
			if (start_date) {
				values.startDate = dateToString(
					startFormat || defaultFormat,
					lang.getRaw().calendar
				)(start_date as Date);
			}
			if (end_date) {
				values.endDate = dateToString(
					endFormat || defaultFormat,
					lang.getRaw().calendar
				)(end_date as Date);
			}
		}

		if (shape?.attached?.show && cardFields.attached?.length) {
			values.attached = cardFields.attached.length;
		}
		if (shape.comments?.show && cardFields.comments?.length) {
			values.comments = cardFields.comments?.length;
		}
		if (shape.votes?.show && cardFields.votes?.length) {
			values.votes = cardFields.votes?.length;
		}

		return values;
	}
	$: footerValues = getFooterValues(cardFields, cardShape);

	let currentUser: Writable<TID> = null;
	$: if (api) currentUser = api.getReactiveState().currentUser;

</script>

<div
	class="wx-footer"
	class:wx-with-content={!!Object.keys(footerValues).length}>
	{#if footerValues.users}
		<div class="wx-users">
			{#each footerValues.users as user (user.id)}
				<UserIcon data={user} noTransform={user.id === '$total'} />
			{/each}
		</div>
	{/if}

	<div class="wx-card-icons">
		<div class="wx-icons-container">
			<!-- hack for left alignment of icons -->
			{#if footerValues.endDate || footerValues.startDate}
				<div class="wx-date">
					<Icon name="wxi-calendar" />
					{#if footerValues.startDate}
						<span
							class="wx-date-value">{footerValues.startDate}</span>
					{/if}
					{#if footerValues.endDate && footerValues.startDate}-{/if}
					{#if footerValues.endDate}
						<span
							class="wx-date-value">{footerValues.endDate}</span>
					{/if}
				</div>
			{/if}
		</div>

		<div class="wx-icons-container">
			{#if footerValues.votes}
				<div
					class="wx-votes"
					class:wx-kanban-editor-voted={cardFields.votes.includes($currentUser)}>
					<Icon name="wxi-like" />
					<span class="wx-item-value">{footerValues.votes}</span>
				</div>
			{/if}
			{#if footerValues.comments}
				<div class="wx-comments">
					<Icon name="wxi-message" />
					<span class="wx-item-value">{footerValues.comments}</span>
				</div>
			{/if}
			{#if footerValues.attached}
				<div class="wx-attached">
					<Icon name="wxi-paperclip" />
					<span class="wx-item-value">{footerValues.attached}</span>
				</div>
			{/if}
		</div>
	</div>
</div>

<style>
	.wx-footer {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
	.wx-with-content {
		padding-top: var(--wx-kanban-card-field-padding);
	}
	.wx-users {
		display: flex;
	}
	.wx-date {
		display: flex;
		align-items: center;
		color: var(--wx-color-font-alt);
	}

	.wx-item-value,
	.wx-date-value {
		padding: 0 4px;
		white-space: nowrap;
	}

	.wx-card-icons,
	.wx-attached,
	.wx-comments,
	.wx-votes {
		display: flex;
		align-items: center;
	}

	.wx-card-icons {
		justify-content: space-between;
	}

	.wx-icons-container {
		display: flex;
		gap: var(--wx-padding);
	}

	.wx-kanban-editor-voted :global(i) {
		color: var(--wx-color-primary);
	}

</style>
