<script lang="ts">
	import type { ICard, ICardShape } from "@xbs/lib-kanban";

	export let cardFields: ICard;
	export let cardShape: ICardShape;

	function getHeaderValues(cardFields: ICard, shape: ICardShape) {
		let values = [];

		if (shape.priority?.show) {
			const priorityValue = shape.priority.values?.find(
				priority => priority.id === cardFields.priority
			);

			if (priorityValue) {
				values.push({
					type: "priority",
					value: priorityValue.label,
					color: priorityValue.color,
				});
			}
		}

		const customFieldsConfig = shape.headerFields;
		if (customFieldsConfig) {
			const customFields = customFieldsConfig.reduce((total, field) => {
				if (cardFields[field.key]) {
					total.push({
						value: cardFields[field.key],
						label: field.label,
						css: field.css,
					});
				}
				return total;
			}, []);
			if (customFields) {
				values.push(...customFields);
			}
		}
		return values;
	}

	$: headerValues = getHeaderValues(cardFields, cardShape);

</script>

<div class="wx-card-header">
	{#each headerValues as field}
		{#if field.value}
			{#if field.type === 'priority'}
				<div
					class="wx-field wx-priority"
					style="background:{field.color}">
					<span class="wx-priority-label"> {field.value} </span>
				</div>
			{:else}
				<div class="wx-field {field.css || ''}">
					{#if field?.label}
						<span class="wx-label"> {field.label}: </span>
					{/if}
					<span class="wx-value"> {field.value} </span>
				</div>
			{/if}
		{/if}
	{/each}
</div>

<style>
	.wx-card-header {
		display: flex;
		align-items: center;
	}
	.wx-card-header .wx-field {
		margin-right: 10px;
		line-height: 25px;
		font-size: var(--wx-font-size);
		margin-bottom: var(--wx-kanban-card-field-padding);
	}

	.wx-card-header .wx-field.wx-priority {
		font-weight: 500;
		line-height: 22px;
		height: 22px;
		padding: 0 6px;
		border-radius: 2px;
		color: #fff;
	}
	.wx-priority-label {
		text-transform: capitalize;
	}

</style>
