<script lang="ts">
	import en from "../en";
	import { en as coreEn } from "@xbs/wx-core-locales";

	import type {
		ICard,
		IColumn,
		TEditorShape,
		IRow,
		THandlersConfig,
		TReadonlyConfig,
		TRenderType,
		TScrollType,
		TLayoutType,
		TCardShape,
		TColumnShape,
		TRowShape,
		ILink,
		IKanbanEditorConfig,
		TID,
	} from "@xbs/lib-kanban";

	import type { IEventBus } from "@xbs/lib-state";
	import { EventBusRouter } from "@xbs/lib-state";

	import { Globals, Locale } from "@xbs/svelte-wx";
	import { createEventDispatcher } from "svelte";
	import { writable } from "svelte/store";

	import {
		getApi,
		defaultCardShape,
		DataStore,
		defaultEditorConfig,
	} from "@xbs/lib-kanban";

	import Layout from "./Layout.svelte";

	export let columns: IColumn[];
	export let rows: IRow[] = null;
	export let cards: ICard[];
	export let cardShape: TCardShape = defaultCardShape;
	export let columnShape: TColumnShape = null;
	export let rowShape: TRowShape = null;
	export let editorShape: TEditorShape[] = null;
	export let readonly: TReadonlyConfig = false;
	export let columnKey = "column";
	export let rowKey = "";
	export let scrollType: TScrollType = "default";
	export let renderType: TRenderType = "default";
	export let cardHeight: number | null = null;
	export let cardTemplate = null;
	export let editor: IKanbanEditorConfig = defaultEditorConfig;
	export let history = true;
	export let currentUser: TID = null;
	export let links: ILink[] = null;

	/** @deprecated use editor.autoSave instead */
	export let editorAutoSave = true;
	$: {
		if (editorAutoSave === false) {
			editor.autoSave = false;
		}
	}

	const dispatch = createEventDispatcher();
	const dataStore = new DataStore(x => writable(x), { history });
	let lastInRoute: IEventBus<THandlersConfig> = new EventBusRouter(
		dispatch as any
	);

	dataStore.out.setNext(lastInRoute.exec);
	export const api = getApi(dataStore, lastInRoute);

	$: {
		dataStore.init({
			columnKey,
			rowKey,
			columns,
			rows,
			cards,
			cardsMap: {},
			cardsMeta: {},
			cardShape,
			columnShape,
			rowShape,
			readonly,
			layout,
			cardHeight,
			currentUser,
			links,
		});
	}

	$: layout = `${scrollType}:${renderType}` as TLayoutType;

	const handleAction = ({ detail: { action, data } }) => {
		api.exec(action, data);
	};

</script>

<Locale words={{ ...coreEn, ...en }} optional>
	<Globals>
		<Layout
			{api}
			{editorShape}
			{cardTemplate}
			{layout}
			{editor}
			on:action={handleAction} />
	</Globals>
</Locale>
