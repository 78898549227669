<script lang="ts">
	import type { Writable } from "svelte/store";

	import Card from "../Card.svelte";
	import CardContainer from "../CardContainer.svelte";
	import VirtualList from "../../lib/VirtualList.svelte";

	import { includesId, isSameId } from "@xbs/lib-kanban";
	import type {
		ICard,
		ICardShape,
		IColumn,
		TID,
		IScrollConfig,
	} from "@xbs/lib-kanban";

	export let column: IColumn;
	export let cards: ICard[];
	export let overCardId: TID;
	export let overColId: TID;
	export let movedCardId: TID;
	export let selected: TID[];
	export let cardShape: ICardShape;
	export let cardTemplate = null;
	export let cardsMeta = {};
	export let cardHeight: number = null;

	export let movedCardHeight = 0;
	export let areaId: TID;
	export let scrollTo: Writable<IScrollConfig>;
	export let isMenuVisible;

	export let api;

	$: items = cards.filter(card => {
		return (
			!isSameId(movedCardId, card.id) && !isSameId(overColId, column.id)
		);
	});

	let scrollToId: TID;
	$: {
		if ($scrollTo && $scrollTo.to === "card" && $scrollTo.id) {
			if (items.find(card => isSameId(card.id, $scrollTo.id))) {
				scrollToId = $scrollTo.id;
				$scrollTo = null;
			}
		}
	}

</script>

{#if cards}
	<div class="wx-list-wrapper" data-id="scroll-column">
		<VirtualList {items} let:item={card} {scrollToId}>
			<div slot="item">
				{#if isSameId(card.id, overCardId)}
					<div
						class="wx-drop-area"
						style="min-height:{movedCardHeight}px;" />
				{/if}

				<CardContainer
					{api}
					cardTemplate={cardTemplate || Card}
					cardFields={card}
					{cardHeight}
					on:action
					dragging={cardsMeta[card.id]?.dragging}
					selected={includesId(selected, card.id)}
					meta={cardsMeta && cardsMeta[card.id]}
					{cardShape}
					menu={isMenuVisible[card.id]} />
			</div>

			<div slot="extra">
				{#if !overCardId && isSameId(overColId, areaId)}
					<div
						class="wx-drop-area"
						style="min-height:{movedCardHeight}px;" />
				{/if}
			</div>
		</VirtualList>
	</div>
{/if}

<style>
	.wx-list-wrapper {
		height: var(--wx-kanban-column-height);
	}
	.wx-drop-area {
		width: 100%;
		border-radius: var(--wx-kanban-card-border-radius);
		margin-top: var(--wx-padding);
		border: dotted 1px var(--wx-color-primary);
	}

</style>
