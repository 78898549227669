<script lang="ts">
	import type { INormalizedCard, ICardShape } from "@xbs/lib-kanban";

	import Icon from "../lib/Icon.svelte";
	import Progress from "../lib/Progress.svelte";

	import Footer from "./cardFields/Footer.svelte";
	import Header from "./cardFields/Header.svelte";

	export let cardFields: INormalizedCard;
	export let cardShape: ICardShape;
	export let menu = true;
	export let api;

	$: coverAttachment = cardFields?.attached?.find(obj => obj.isCover);
	$: coverURL = coverAttachment
		? coverAttachment.coverURL || coverAttachment.url
		: null;

	function getCss(
		cardFields: INormalizedCard,
		cardShape: ICardShape
	): () => string {
		let contentClasses = "wx-content";
		if (cardFields.css) contentClasses += " " + cardFields.css;
		if (cardShape.css)
			return () =>
				(contentClasses += ` ${(
					cardShape.css as (obj: INormalizedCard) => string
				)(cardFields)}`);

		return () => contentClasses;
	}

	$: css = getCss(cardFields, cardShape)();

</script>

{#if cardShape.color?.show && cardFields.color}
	<div class="wx-color wx-rounded" style="background:{cardFields.color}" />
{/if}
{#if cardShape?.cover?.show && coverURL}
	<div
		class="wx-field wx-image"
		class:wx-rounded={!(cardShape.color?.show && cardFields.color)}>
		<img src={coverURL} alt="" />
	</div>
{/if}
<div class={css}>
	<Header {cardFields} {cardShape} />
	<div class="wx-body">
		<div class="wx-field wx-label">
			{#if cardShape?.label?.show && cardFields.label}
				<span> {cardFields.label} </span>
			{/if}
			{#if menu}
				<div class="wx-menu" data-ignore-selection="true">
					<div data-menu-id={cardFields.id}>
						<Icon name={'wxi-dots-v'} clickable />
					</div>
				</div>
			{/if}
		</div>
		{#if cardShape?.description?.show && cardFields.description}
			<div class="wx-field wx-description">{cardFields.description}</div>
		{/if}

		{#if cardShape?.progress?.show && cardFields.progress}
			<div class="wx-field">
				<Progress
					min={cardShape.progress?.config?.min || 0}
					max={cardShape.progress?.config?.max || 100}
					value={cardFields.progress} />
			</div>
		{/if}
	</div>

	<Footer {cardFields} {cardShape} {api} />
</div>

<style>
	.wx-content {
		padding: 20px 16px;

		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
	}

	.wx-color {
		height: 5px;
		min-height: 5px;
		width: 100%;
	}
	.wx-rounded {
		border-radius: var(--wx-kanban-card-border-radius)
			var(--wx-kanban-card-border-radius) 0 0;
	}
	.wx-field {
		margin-bottom: var(--wx-kanban-card-field-padding);
	}

	.wx-body .wx-field:last-child {
		margin-bottom: 0;
	}
	.wx-label {
		font-weight: var(--wx-font-weight-md);
		font-size: var(--wx-font-size);
		min-height: 1em;
		max-width: 100%;
		position: relative;
		padding-right: 10px;
		word-break: break-word;
	}
	.wx-description {
		font-size: var(--wx-font-size);
		/* [todo] uses non standard properties, try to implement another way */
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	.wx-image {
		overflow: hidden;
		width: 100%;
		height: 150px;
		margin-bottom: 0;
	}
	.wx-image img {
		width: 100%;
		height: 100%;
	}
	.wx-menu {
		position: absolute;
		top: 0;
		right: -5px;
	}

</style>
