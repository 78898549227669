<script lang="ts">
	import { createEventDispatcher, getContext } from "svelte";

	import Card from "./Card.svelte";
	import CardContainer from "./CardContainer.svelte";
	import Icon from "../lib/Icon.svelte";

	import { getAreaId, includesId, isSameId } from "@xbs/lib-kanban";
	import type {
		IApi,
		ICard,
		INormalizedCard,
		IColumn,
		IColumnShape,
		IRow,
		TEventDispatcher,
		TAreaMeta,
	} from "@xbs/lib-kanban";
	import VirtualScrollColumn from "./columnLayouts/VirtualScrollColumn.svelte";
	import ScrollColumn from "./columnLayouts/ScrollColumn.svelte";

	export let column: IColumn;
	export let areaMeta: TAreaMeta;
	export let row: IRow;
	export let cards: INormalizedCard[];
	export let cardTemplate = null;
	export let api: IApi;
	export let isMenuVisible;

	const _ = getContext<any>("wx-i18n").getGroup("kanban");

	const {
		selected: rSelected,
		cardShape: rCardShape,
		cardsMeta: rCardsMeta,
		scroll: scrollTo,
		readonly: rReadonly,
		columnShape,
		before: rOverCardId,
		overAreaId: rOverColId,
		dragItemId: rMovedCardId,
		dragItemsCoords: rDragItemsCoords,
		layout: rLayout,
		cardHeight: rCardHeight,
		cardsMap,
	} = api.getReactiveState();

	$: add = $rReadonly.add;
	$: movedCardCoords = $rDragItemsCoords && $rDragItemsCoords[$rMovedCardId];

	$: selected = $rSelected;
	$: cardShape = $rCardShape;
	$: cardsMeta = $rCardsMeta;
	$: overCardId = $rOverCardId;
	$: overColId = $rOverColId;
	$: movedCardId = $rMovedCardId;
	$: layout = $rLayout;
	$: cardHeight = $rCardHeight;

	const dispatch: TEventDispatcher = createEventDispatcher();
	function handleAddCardClick(e: MouseEvent) {
		e.stopPropagation();
		dispatch("action", {
			action: "add-card",
			data: {
				columnId: column.id,
				rowId: row.id,
				card: {
					label: _("Untitled"),
				},
			},
		});
	}

	$: areaId = getAreaId(column.id, row.id);
	$: areaHeight = areaMeta?.height;
	$: areaDragHeight = areaHeight ? `${areaHeight}px` : "auto";

	const borders = 2;
	$: movedCardHeight = (movedCardCoords?.height || 50) - borders;

	function buildColumnCss(
		columnShape: IColumnShape,
		column: IColumn,
		isOverLimit: boolean,
		cards: ICard[]
	) {
		let columnClass = "wx-column";
		if (column.collapsed) columnClass += " wx-collapsed";
		if (isOverLimit) columnClass += " wx-over-limit";
		if (column.css) columnClass += " " + column.css;
		if (columnShape && columnShape.css)
			columnClass += " " + columnShape.css(column, cards);
		return columnClass;
	}

	$: columnClass = buildColumnCss(
		$columnShape,
		column,
		areaMeta.isOverLimit,
		$cardsMap[column.id]
	);

</script>

<div
	class={columnClass}
	data-drop-area={areaId}
	style="min-height:{areaDragHeight};">
	{#if !column.collapsed}
		{#if layout === 'column:lazy'}
			<VirtualScrollColumn
				{api}
				{column}
				{cards}
				{overCardId}
				{overColId}
				{movedCardId}
				{selected}
				{cardShape}
				{cardTemplate}
				{cardsMeta}
				{movedCardHeight}
				{areaId}
				{scrollTo}
				{cardHeight}
				{isMenuVisible}
				on:action />
		{:else if layout === 'column:default'}
			<ScrollColumn
				{api}
				{cards}
				{overCardId}
				{overColId}
				{selected}
				{cardShape}
				{cardTemplate}
				{cardsMeta}
				{movedCardHeight}
				{areaId}
				{cardHeight}
				{isMenuVisible}
				on:action />
		{:else}
			{#if cards}
				{#each cards as card (card.id)}
					{#if isSameId(card.id, overCardId)}
						<div
							class="wx-drop-area"
							style="min-height:{movedCardHeight}px;" />
					{/if}
					<CardContainer
						{api}
						cardTemplate={cardTemplate || Card}
						cardFields={card}
						on:action
						dragging={cardsMeta[card.id]?.dragging}
						selected={includesId(selected, card.id)}
						meta={cardsMeta && cardsMeta[card.id]}
						{cardShape}
						{cardHeight}
						menu={isMenuVisible[card.id]} />
				{/each}
			{/if}
			{#if !overCardId && isSameId(overColId, areaId)}
				<div
					class="wx-drop-area"
					style="min-height:{movedCardHeight}px;" />
			{/if}
		{/if}

		<div class="wx-controls-wrapper">
			{#if add && !areaMeta.noFreeSpace}
				<div class="wx-add-card-btn" on:click={handleAddCardClick}>
					<Icon name="wxi-plus" />
					<span class="wx-add-card-tip">
						{_('Add new card')}...
					</span>
				</div>
			{/if}
			{#if areaMeta.rowId && column.limit}
				<div class="wx-swimlane-limit">
					{areaMeta.cardsCount}/{areaMeta.totalLimit}
				</div>
			{/if}
		</div>
	{:else}
		<div class="wx-collapsed-label">
			<div class="wx-label-text">{column.label}</div>
		</div>
	{/if}
	{#if column.overlay}
		<svelte:component this={column.overlay} />
	{/if}
</div>

<style>
	.wx-add-card-tip {
		opacity: 0;
		transition: all 0.2s ease-in;
		color: var(--wx-color-font-alt);
	}
	.wx-column {
		min-width: var(--wx-kanban-column-width);
		width: var(--wx-kanban-column-width);

		margin-left: 12px;
		position: relative;
	}
	.wx-column:last-child {
		margin-right: 12px;
	}
	.wx-column:hover .wx-add-card-tip {
		opacity: 1;
	}
	.wx-swimlane-limit {
		padding-right: var(--wx-padding);
		background: var(--wx-kanban-background);
		padding: 2px var(--wx-padding);
		border-radius: var(--wx-kanban-card-border-radius);
		border: var(--wx-kanban-card-border);
		position: absolute;
		right: 0;
	}
	.wx-controls-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		min-height: 24px;
	}
	.wx-over-limit .wx-swimlane-limit {
		border: solid 1px var(--wx-kanban-over-limit-color);
	}
	.wx-collapsed {
		max-width: var(--wx-kanban-collapsed-column-width);
		width: var(--wx-kanban-collapsed-column-width);
		min-width: 0px;
		background: var(--wx-kanban-collapsed-background);
		box-shadow: var(--wx-kanban-shadow);
		padding: var(--wx-kanban-collapsed-padding) 0;
		margin-top: var(--wx-kanban-collapsed-margin);
		margin-bottom: 23px;
		border-radius: var(--wx-kanban-card-border-radius);
	}
	.wx-collapsed-label {
		writing-mode: tb-rl;
		font-weight: 500;
		font-size: var(--wx-font-size);
		margin: 10px 0;

		display: flex;
		align-items: center;
		width: 100%;
		z-index: 5;
	}
	.wx-label-text {
		white-space: nowrap;
		transform: rotate(180deg);
		z-index: 5;
		pointer-events: none;
	}
	.wx-add-card-btn {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 4px;
		font-size: var(--wx-font-size);
		margin-top: var(--wx-padding);
		margin-bottom: var(--wx-padding);
		cursor: pointer;
	}

	.wx-drop-area {
		width: 100%;
		border-radius: var(--wx-kanban-card-border-radius);
		margin-top: var(--wx-padding);
		border: dotted 1px var(--wx-color-primary);
	}

</style>
