<script lang="ts">
	import {
		isSameId,
		type IApi,
		type ICard,
		type ILink,
		type TID,
		type TRelationOptions,
	} from "@xbs/lib-kanban";
	import Icon from "../lib/Icon.svelte";
	import { getContext } from "svelte";
	import { Combo, Button } from "@xbs/svelte-wx";

	export let api: IApi;
	export let card: ICard;

	const _ = getContext<any>("wx-i18n").getGroup("kanban");
	const { cards: storeCards, links: storeLinks } = api.getReactiveState();

	const relationOptions = [
		{ id: 1, relation: "relatesTo", master: false, label: _("Relates to") },
		{
			id: 2,
			relation: "requiredFor",
			master: true,
			label: _("Is required for"),
		},
		{
			id: 3,
			relation: "requiredFor",
			master: false,
			label: _("Depends on"),
		},
		{ id: 4, relation: "duplicate", master: true, label: _("Duplicates") },
		{
			id: 5,
			relation: "duplicate",
			master: false,
			label: _("Is duplicated by"),
		},
		{ id: 6, relation: "parent", master: true, label: _("Is parent for") },
		{ id: 7, relation: "parent", master: false, label: _("Is subtask of") },
	];

	let selectedRelationId: TID = null;
	let selectedCardId: TID = null;
	let cardOptions = [];
	let relation: TRelationOptions = null;
	let isCardSelectDisabled = true;
	let addLinkMode = false;

	interface IDisplayedLink extends ILink {
		isMaster?: boolean;
		label?: string;
	}

	let links: IDisplayedLink[];
	$: links = $storeLinks
		.filter(
			l => isSameId(l.masterId, card.id) || isSameId(l.slaveId, card.id)
		)
		.map(l => {
			const dlink: IDisplayedLink = { ...l };
			dlink.isMaster = isSameId(dlink.masterId, card.id);
			dlink.label = relationOptions.find(
				o =>
					o.relation === dlink.relation &&
					(dlink.relation === "relatesTo" ||
						dlink.isMaster === o.master)
			).label;
			return dlink;
		});

	function handleAddClick(): void {
		addLinkMode = true;
	}

	function handleCancel(): void {
		addLinkMode = false;
		isCardSelectDisabled = true;
		selectedCardId = selectedRelationId = "";
	}

	function getRelatedCardLabel(link: IDisplayedLink): string {
		const related = link.isMaster ? "slaveId" : "masterId";
		const card = $storeCards.find(c => isSameId(c.id, link[related]));
		return card.label;
	}

	$: {
		cardOptions =
			links && $storeCards.filter(c => !isSameId(c.id, card.id));
		handleCancel();
	}

	function filterCardOptions() {
		cardOptions = cardOptions.filter(c => {
			return !links.find(
				l =>
					((isSameId(c.id, l.slaveId) &&
						isSameId(l.masterId, card.id)) ||
						(isSameId(c.id, l.masterId) &&
							isSameId(l.slaveId, card.id))) &&
					l.relation === relation
			);
		});
	}

	function handleAddLink() {
		if (selectedRelationId && selectedCardId) {
			const isMaster = relationOptions.find(r =>
				isSameId(r.id, selectedRelationId)
			).master;
			api.exec("add-link", {
				link: {
					masterId: isMaster ? card.id : selectedCardId,
					slaveId: !isMaster ? card.id : selectedCardId,
					relation,
				},
			});
			handleCancel();
		}
	}

	function handleDelete(id: TID) {
		api.exec("delete-link", { id });
	}

	function handleRelationSelectorChange() {
		if (selectedRelationId)
			relation = relationOptions.find(r =>
				isSameId(r.id, selectedRelationId)
			).relation as TRelationOptions;

		filterCardOptions();

		selectedCardId = "";
		isCardSelectDisabled = false;
	}

	function goToRelatedLink(link: IDisplayedLink) {
		const related = link.isMaster ? "slaveId" : "masterId";
		api.exec("select-card", { id: link[related] });
	}

</script>

<div class="wx-links">
	{#each links as link (link.id)}
		<div class="wx-link">
			<div class="wx-link-content">
				<div class="wx-relates">{link.label}</div>
				<div class="wx-task" on:click={() => goToRelatedLink(link)}>
					{getRelatedCardLabel(link)}
				</div>
			</div>
			<div class="wx-delete-icon">
				<Icon
					name="wxi-delete-outline"
					click={() => handleDelete(link.id)} />
			</div>
		</div>
	{/each}

	{#if addLinkMode}
		<div class="wx-set-link">
			<div class="wx-combos-wrapper">
				<div class="wx-relates-combo">
					<Combo
						options={relationOptions}
						bind:value={selectedRelationId}
						placeholder={_('Select a relation')}
						on:select={() => handleRelationSelectorChange()}
						let:option>
						{option.label}
					</Combo>
				</div>
				<div class="wx-tasks-combo">
					<Combo
						options={cardOptions}
						bind:value={selectedCardId}
						placeholder={_('Select a task')}
						disabled={isCardSelectDisabled}
						let:option>
						{option.label}
					</Combo>
				</div>
			</div>
			<div class="wx-btns-wrapper">
				<div class="wx-cancel-btn">
					<Button type="secondary block" click={handleCancel}>
						{_('Cancel')}
					</Button>
				</div>
				<div class="wx-link-btn">
					<Button type="primary block" click={() => handleAddLink()}>
						{_('Link Task')}
					</Button>
				</div>
			</div>
		</div>
	{/if}
	<div class="wx-add-link" on:click={handleAddClick}>
		<Icon name="wxi-plus" />
		<span>{_('Add link')}</span>
	</div>
</div>

<style>
	.wx-links {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		gap: 12px;
	}
	.wx-link {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
	.wx-relates {
		font-size: var(--wx-font-size-sm);
		line-height: var(--wx-line-height-sm);
		color: var(--wx-color-font-alt);
	}
	.wx-task {
		cursor: pointer;
		color: var(--wx-color-primary);
		text-decoration: underline;
	}
	.wx-delete-icon {
		visibility: hidden;
		cursor: pointer;
	}
	.wx-link:hover .wx-delete-icon {
		visibility: visible;
	}
	.wx-add-link {
		display: flex;
		align-items: center;
		gap: 4px;
		justify-content: flex-start;
		font-size: var(--wx-font-size);
		margin-top: var(--wx-padding);
		margin-bottom: var(--wx-padding);
		color: var(--wx-color-font-alt);
		cursor: pointer;
	}
	.wx-add-link:hover,
	.wx-delete-icon:hover {
		opacity: 0.7;
	}
	.wx-set-link {
		display: flex;
		flex-direction: column;
		gap: 8px;
		width: 100%;
	}
	.wx-combos-wrapper {
		display: flex;
		gap: 8px;
	}
	.wx-relates-combo {
		flex: 0 0 33%;
	}
	.wx-tasks-combo {
		flex: 0 0 66%;
	}
	.wx-btns-wrapper {
		display: flex;
		align-self: flex-end;
		gap: 8px;
	}
	.wx-btns-wrapper .wx-link-btn {
		width: fit-content;
	}

</style>
