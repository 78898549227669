<script lang="ts">
	import type { TWxIcons, customIcons } from "@xbs/lib-kanban";

	import Icon from "../Icon.svelte";

	export let name = "";
	export let icon: TWxIcons | keyof typeof customIcons;
	export let label: string;
	export let disabled = false;

	export let click: any = null;

	function handleClick() {
		if (click) {
			click(name);
		}
	}

</script>

<div
	class="wx-control"
	class:wx-disabled={disabled}
	on:click={handleClick}
	title={label}>
	<Icon name={icon} />
</div>

<style>
	.wx-control {
		display: flex;
		justify-content: center;
		align-items: center;

		cursor: pointer;
		padding: 4px;
		margin-left: 4px;
		transition: background-color 0.2s ease-out;
	}
	.wx-control:hover {
		border-radius: var(--wx-input-border-radius);
		background-color: var(--wx-kanban-toolbar-control-hover);
	}
	.wx-control:active {
		background-color: var(--wx-kanban-toolbar-control-active);
	}

	.wx-disabled {
		opacity: 0.5;
		pointer-events: none;
	}

</style>
