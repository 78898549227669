<script lang="ts">
	import { getContext } from "svelte";
	import { getSortConfig } from "@xbs/lib-kanban";
	import { uid } from "@xbs/lib-core-dom";

	import type {
		IApi,
		TSortDir,
		ISortConfig,
		ISortItemOption,
	} from "@xbs/lib-kanban";
	import type { Writable } from "svelte/store";

	import Icon from "../Icon.svelte";

	import { DropDownMenu } from "@xbs/svelte-menu";

	const _ = getContext<any>("wx-i18n").getGroup("kanban");

	export let api: IApi;
	export let options: ISortItemOption[] = null;

	$: {
		options = Array.isArray(options) ? options : getSortConfig();

		options = options.map(el => {
			let { id, text, label, dir } = el;
			return {
				...el,
				id: id || (uid() as any),
				text: _(text || label),
				icon: dir === "asc" ? "wxi-asc" : "wxi-desc",
			};
		});
	}

	let preserve = null;
	let stateSort: Writable<ISortConfig | null>;
	$: {
		if (!stateSort) {
			stateSort = api.getReactiveState()
				.sort as Writable<ISortConfig | null>;
		}
		if ($stateSort?.preserve) {
			preserve = options.find(
				el => el.by === $stateSort.by && el.dir === $stateSort.dir
			);
		} else {
			preserve = null;
		}
	}

	function handleMenuAction(ev: any) {
		const action = ev?.detail.action;
		if (action) {
			const sortRule = options.find(s => s.id === action.id);

			if (sortRule) {
				api.exec("set-sort", {
					by: sortRule.by,
					dir: sortRule.dir as TSortDir,
				});
			}
		}
	}

	function handleReset() {
		api.exec("set-sort", null);
	}

</script>

{#if preserve}
	<div class="wx-preserve">
		<Icon name="wxi-close" click={handleReset} />
		{preserve.text}
	</div>
{/if}
<DropDownMenu {options} at={'left-bottom'} on:click={handleMenuAction}>
	<div class="wx-control" title={_('Sort')}>
		<Icon name="wxi-sort" />
	</div>
</DropDownMenu>

<style>
	.wx-control {
		display: flex;
		justify-content: center;
		align-items: center;

		cursor: pointer;
		padding: 4px;
		margin-left: 4px;
		transition: background-color 0.2s ease-out;
	}
	.wx-control:hover {
		border-radius: var(--wx-input-border-radius);
		background-color: rgba(0, 0, 0, 0.07);
	}
	.wx-control:active {
		background-color: rgba(0, 0, 0, 0.15);
	}

	.wx-preserve {
		padding: 4px;
		padding-right: 8px;
		display: flex;
		align-items: center;
		background: var(--wx-kanban-background);
	}

</style>
