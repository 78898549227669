<script lang="ts">
	export let label = "";
	export let min = 0;
	export let max = 100;
	export let value = 0;
	export let showValue = true;

	let progress = "0";
	let bgStyle = "";

	$: {
		progress = Math.round(((value - min) / (max - min)) * 100) + "%";
		bgStyle = `background: linear-gradient(90deg, var(--wx-color-primary) 0% ${progress}, var(--wx-kanban-progress-inactive-color) ${progress} 100%);`;
	}

</script>

<div class="wx-layout">
	{#if label}
		<div class="wx-label">{label}</div>
	{/if}
	<div class="wx-wrap">
		<div class="wx-progress" style={bgStyle} />
		{#if showValue}
			<div class="wx-value">{progress}</div>
		{/if}
	</div>
</div>

<style>
	.wx-layout {
		margin-bottom: 5px;
		min-width: 100%;
	}

	.wx-label {
		display: block;
		margin: var(--wx-label-margin);
		padding: var(--wx-label-padding);
		font-family: var(--wx-label-font-family);
		font-size: var(--wx-label-font-size);
		line-height: var(--wx-label-line-height);
		font-weight: var(--wx-label-font-weight);
		color: var(--wx-label-font-color);
	}

	.wx-wrap {
		display: flex;
		align-items: center;
	}

	.wx-progress {
		width: 100%;
		border-radius: 4px;
		height: var(--wx-progress-height);
		min-height: var(--wx-progress-height);
		max-height: var(--wx-progress-height);
	}

	.wx-value {
		min-width: 35px;
		text-align: end;
	}

</style>
