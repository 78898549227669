<script lang="ts">
	import { createEventDispatcher } from "svelte";

	export let value = "";
	export let focus = false;
	export let placeholder = "";
	export let resize = true;

	const dispatch = createEventDispatcher();

	function handleInput(e: any) {
		value = e.target.value;
	}
	function handleKeydown(e: KeyboardEvent) {
		if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
			(e as any).target.blur();
		}
	}
	function handleBlur() {
		dispatch("blur", { value });
	}
	function focusAction(node: HTMLElement) {
		if (focus) {
			node.focus();
		}
	}

</script>

<textarea
	type="text"
	class="wx-textarea"
	class:wx-no-resize={!resize}
	{value}
	{placeholder}
	rows={5}
	on:input={handleInput}
	on:keydown={handleKeydown}
	on:blur={handleBlur}
	use:focusAction />

<style>
	.wx-textarea {
		padding: var(--wx-input-padding);
		outline: none;
		flex: 1;
		color: var(--wx-color-font);
		width: 100%;
		box-sizing: border-box;

		border: var(--wx-input-border);
		border-radius: var(--wx-input-border-radius);

		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		background: var(--wx-kanban-content-background);
	}
	.wx-textarea:focus {
		border: 1px solid var(--wx-color-primary);
	}
	.wx-no-resize {
		resize: none;
	}

	.wx-textarea::placeholder {
		color: var(--wx-color-font-disabled);
	}

</style>
