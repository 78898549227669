<script lang="ts">
	import en from "../en";
	import { en as coreEn } from "@xbs/wx-core-locales";
	import { Locale } from "@xbs/svelte-wx";
	import { elementsIds } from "@xbs/lib-kanban";
	import type { IApi } from "@xbs/lib-kanban";

	export let api: IApi;

</script>

<Locale words={{ ...coreEn, ...en }} optional>
	<div class="wx-toolbar" data-wx-widget={elementsIds.toolbar}>
		{#if api}
			<slot />
		{/if}
	</div>
</Locale>

<style>
	.wx-toolbar {
		display: flex;
		flex-direction: row;
		align-items: var(--wx-kanban-toolbar-align);
		justify-content: var(--wx-kanban-toolbar-justify);
		height: var(--wx-kanban-toolbar-height);
		min-height: var(--wx-kanban-toolbar-height);
		width: 100%;
		padding: var(--wx-padding);
		background: var(--wx-kanban-content-background);
		color: var(--wx-color-font);
		border-bottom: var(--wx-kanban-toolbar-border);
	}

</style>
