<script lang="ts">
	import { getContext } from "svelte";
	import { tempID } from "@xbs/lib-state";
	import type { IApi } from "@xbs/lib-kanban";

	import IconButton from "./IconButton.svelte";
	export let api: IApi;

	const _ = getContext<any>("wx-i18n").getGroup("kanban");

	function handleClick() {
		api.exec("add-column", {
			id: tempID(),
			column: { label: _("Untitled") },
		});
	}

</script>

<IconButton
	click={handleClick}
	label={_('Add new column')}
	icon="wxi-table-column-plus-after" />
