<script lang="ts">
	import {
		isSameId,
		type IApi,
		type ICard,
		type IComment,
		type IUser,
		type TCommentsShape,
		type TID,
	} from "@xbs/lib-kanban";

	import { Button } from "@xbs/svelte-wx";
	import { dateToString } from "@xbs/lib-core-dom";

	import { ContextMenu } from "@xbs/svelte-menu";

	import UserIcon from "../lib/UserIcon.svelte";
	import Textarea from "../lib/Textarea.svelte";
	import Icon from "../lib/Icon.svelte";

	import { getContext } from "svelte";
	import { tempID } from "@xbs/lib-state";
	export let api: IApi;

	export let card: ICard;
	export let users: IUser[] = [];
	export let shape: TCommentsShape | any;

	const lang = getContext<any>("wx-i18n");
	const _ = lang.getGroup("kanban");
	const { showModal } = getContext<any>("wx-helpers");

	const { cards, currentUser } = api.getReactiveState();

	$: comments = $cards.find(c => c.id === card?.id)?.comments || [];

	const config = shape.config || {};

	const usersById = users.reduce((acc, user) => {
		acc[user.id] = user;
		return acc;
	}, {});

	const dateFormat = config.format || "%M %d %Y %h:%i";
	const dateFormatter = dateToString(dateFormat, lang.getRaw().calendar);

	let inputValue = "";
	function handleAdd() {
		if (inputValue) {
			api.exec("add-comment", {
				id: tempID(),
				cardId: card.id,
				comment: {
					text: inputValue,
					date: new Date(),
				},
			});
			inputValue = "";
		}
	}

	let editId = null;
	let editText = null;

	function handleEdit(comment: IComment) {
		editId = comment.id;
		editText = comment.text;
	}
	function handleEditEnd() {
		if (editText) {
			api.exec("update-comment", {
				cardId: card.id,
				id: editId,
				comment: {
					text: editText,
				},
			});
		}
		cancelEdit();
	}

	function cancelEdit() {
		editId = null;
		editText = null;
	}
	function handleDelete(id: TID) {
		showModal({ message: _("Would you like to delete this comment?") })
			.then(() => {
				api.exec("delete-comment", { cardId: card.id, id });
			})
			.catch(() => {
				/* to avoid unhandled rejected promise error */
			});
	}

	function handleMenuAction(e: any) {
		const { item, action } = e.detail;

		if (action) {
			switch (action.id) {
				case "edit-comment":
					handleEdit(comments.find(c => c.id == item));
					break;
				case "delete-comment":
					handleDelete(item);
					break;
			}
		}
	}
	let showMenu = null;

	const menuItems = [
		{ id: "edit-comment", text: _("Edit"), icon: "wxi-edit-outline" },
		{
			id: "delete-comment",
			text: _("Delete"),
			icon: "wxi-delete-outline",
		},
	];

</script>

<div class="wx-comments">
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	{#if comments.length}
		<div class="wx-comment-list" on:click={showMenu}>
			{#each comments as comment (comment.id)}
				<div class="wx-comment" data-comment-id={comment.id}>
					<div class="wx-comment-icon">
						<UserIcon
							size={'small'}
							border={false}
							data={usersById[comment.userId]} />
					</div>
					<div class="wx-content">
						<div class="wx-comment-header">
							<div class="wx-name">
								{usersById[comment.userId].label}
							</div>

							{#if isSameId(comment.userId, $currentUser)}
								<div class="wx-menu" data-menu-id={comment.id}>
									<Icon name="wxi-dots-v" />
								</div>
							{/if}
						</div>

						<div class="wx-date">{dateFormatter(comment.date)}</div>

						{#if isSameId(editId, comment.id)}
							<Textarea
								resize={false}
								bind:value={editText}
								focus />
							<div class="wx-edit-btns">
								<div class="wx-comment-textarea-btn">
									<Button
										type="secondary block"
										click={cancelEdit}>
										{_('Cancel')}
									</Button>
								</div>
								<div class="wx-comment-textarea-btn">
									<Button
										type="primary block"
										click={handleEditEnd}>
										{_('Save')}
									</Button>
								</div>
							</div>
						{:else if comment.html && config.html}
							{@html comment.html}
						{:else}
							<pre class="wx-text">{comment.text}</pre>
						{/if}
					</div>
				</div>
			{/each}
		</div>
	{:else if !$currentUser}
		<div class="wx-kanban-no-comments">{_('No comments yet')}</div>
	{/if}

	<ContextMenu
		at={'left-bottom'}
		options={menuItems}
		bind:handler={showMenu}
		resolver={item => item}
		dataKey="menuId"
		on:click={handleMenuAction} />

	{#if $currentUser && !editId}
		<div class="wx-new-comment">
			<div class="wx-comment-icon">
				<UserIcon
					size="small"
					border={false}
					data={usersById[$currentUser]} />
			</div>
			<Textarea
				bind:value={inputValue}
				resize={false}
				placeholder={_('Add a comment...')} />
		</div>

		<div class="wx-comment-textarea-btn">
			<Button type="primary block" click={handleAdd}>{_('Send')}</Button>
		</div>
	{/if}
</div>

<style>
	.wx-comments {
		width: var(--wx-field-width);
		max-width: 100%;
		margin-top: 22px;
		display: flex;
		flex-direction: column;
	}

	.wx-comment-list {
		margin-bottom: var(--wx-padding);
	}

	.wx-comment {
		box-sizing: border-box;

		width: 100%;
		min-height: 70px;
		margin: 12px 0;

		position: relative;
		font-size: var(--wx-font-size);

		display: flex;
		flex-direction: row;
	}

	.wx-new-comment {
		display: flex;
		gap: 12px;
	}

	.wx-content {
		width: 100%;
		padding-left: 12px;
	}

	.wx-content > * {
		margin-bottom: 4px;
	}

	.wx-comment-header {
		width: 100%;
		padding-right: 10px;
		position: relative;
	}

	.wx-menu {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}

	.wx-comment-icon {
		align-self: flex-start;
	}
	.wx-date {
		font-size: var(--wx-font-size-sm);
		color: var(--wx-color-font-alt);
	}
	.wx-name {
		font-weight: var(--wx-font-weight-md);
	}
	.wx-text {
		margin: 0;
		white-space: pre-wrap;
		line-height: var(--wx-line-height);
		font-family: var(--wx-font-family);
	}

	.wx-comment-textarea-btn {
		margin-top: var(--wx-padding);
		width: fit-content;
		align-self: flex-end;
	}

	.wx-edit-btns {
		display: flex;
		gap: var(--wx-padding);
		justify-content: flex-end;
	}

	.wx-kanban-no-comments {
		color: var(--wx-color-font-alt);
	}

	:global(.wx-sm-button) {
		--wx-button-padding: 0 10px;
		height: 24px;
	}
	:global(.wx-sm-button:not(:last-child)) {
		margin-right: 10px;
	}

</style>
