<script lang="ts">
	import { createEventDispatcher, onMount, getContext } from "svelte";
	import { Dropdown } from "@xbs/svelte-wx";
	import { uid } from "@xbs/lib-core-dom";
	import Icon from "./Icon.svelte";

	const _ = getContext<any>("wx-i18n").getGroup("kanban");

	export let value = "";
	export let id = uid();
	export let readonly = false;
	export let focus = false;
	export let placeholder = _("Search");

	export let searchResults: { id: string; label: string }[] = null;

	const dispatch = createEventDispatcher();
	let searchFocused = false;

	let input: HTMLElement;
	if (focus) {
		onMount(() => input.focus());
	}

	let searchEl: HTMLElement;

	function handlePopupClose(e: any) {
		if (!searchEl.contains(e.target)) {
			searchFocused = false;
			value = "";
		}
	}

	function handleSearchFocus() {
		searchFocused = true;
		dispatch("action", { action: "search-focus" });
	}

	function handleSearchBlur() {
		dispatch("action", { action: "search-blur" });
	}

	function handleSearchResultClick(id: string) {
		dispatch("action", { action: "result-click", id });
		searchFocused = false;
	}

	function handleClear() {
		value = "";
	}

</script>

<div
	class="wx-search"
	tabindex={1}
	on:click|stopPropagation
	bind:this={searchEl}>
	<div class="wx-search-icon">
		<Icon name="wxi-search" />
	</div>
	<input
		bind:value
		bind:this={input}
		id={`${id}`}
		{readonly}
		{placeholder}
		on:focus={handleSearchFocus}
		on:blur={handleSearchBlur} />
	{#if !!value}
		<div class="wx-close-icon">
			<Icon name="wxi-close" clickable click={handleClear} />
		</div>
	{/if}
	{#if searchFocused}
		<Dropdown cancel={handlePopupClose}>
			<div class="wx-search-popup">
				{#if $$slots?.default}
					<div class="wx-settings">
						<slot />
					</div>
				{/if}
				{#if searchResults}
					<div class="wx-results">
						{#each searchResults as result}
							<div
								class="wx-list-item"
								on:click={() => handleSearchResultClick(result.id)}>
								<span class="wx-list-item-text">
									{result.label}
								</span>
							</div>
						{/each}
					</div>
				{:else}
					<div class="wx-list-item wx-no-results">
						{_('No results')}
					</div>
				{/if}
			</div>
		</Dropdown>
	{/if}
</div>

<style>
	.wx-search {
		position: relative;
		min-width: 300px;
		cursor: pointer;
	}
	@media (max-width: 420px) {
		.wx-search {
			min-width: 100px;
		}
	}
	.wx-search-popup {
		max-height: 300px;
		overflow: auto;
	}
	input {
		padding: 4px;
		padding-left: 28px;
		padding-right: 26px;

		outline: none;
		flex: 1;
		color: var(--wx-color-font);
		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
		width: 100%;
		box-sizing: border-box;

		border: none;
		background-color: transparent;
	}
	input:focus {
		border: none;
	}
	input::placeholder {
		color: var(--wx-color-font-alt);
		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
	}
	.wx-search-icon {
		position: absolute;
		top: calc(50% - 10px);
		left: 4px;
	}
	.wx-close-icon {
		position: absolute;
		top: calc(50% - 10px);
		right: 4px;
	}
	.wx-settings {
		border-bottom: var(--wx-border);
		position: sticky;
		top: 0;
		background-color: var(--wx-kanban-content-background);
	}
	.wx-list-item {
		display: flex;
		align-items: center;

		padding-left: 12px;
		padding-right: 8px;
		height: 32px;

		cursor: pointer;
		overflow: hidden;
	}
	.wx-list-item:hover:not(.wx-no-results) {
		background: var(--wx-background-hover);
	}
	.wx-list-item-text {
		line-height: 20px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
	.wx-no-results {
		color: var(--wx-color-font-alt);
		height: 36px;
		cursor: initial;
	}
	.wx-results {
		margin: 8px 0;
	}

</style>
