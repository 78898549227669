<script lang="ts">
	import Card from "../Card.svelte";
	import CardContainer from "../CardContainer.svelte";

	import { includesId, isSameId, scrollManager } from "@xbs/lib-kanban";
	import type { INormalizedCard, ICardShape, TID } from "@xbs/lib-kanban";

	export let cards: INormalizedCard[];
	export let overCardId: TID;
	export let overColId: TID;
	export let selected: TID[];
	export let cardShape: ICardShape;
	export let cardTemplate = null;
	export let cardsMeta = {};
	export let cardHeight: number = null;

	export let movedCardHeight = 0;
	export let areaId: TID;
	export let isMenuVisible;

	export let api;

</script>

{#if cards}
	<div class="wx-list-wrapper" use:scrollManager>
		{#if cards}
			{#each cards as card (card.id)}
				{#if isSameId(card.id, overCardId)}
					<div
						class="wx-drop-area"
						style="min-height:{movedCardHeight}px;" />
				{/if}
				<CardContainer
					{api}
					cardTemplate={cardTemplate || Card}
					cardFields={card}
					{cardHeight}
					on:action
					dragging={cardsMeta[card.id]?.dragging}
					selected={includesId(selected, card.id)}
					meta={cardsMeta && cardsMeta[card.id]}
					{cardShape}
					menu={isMenuVisible[card.id]} />
			{/each}
			{#if !overCardId && isSameId(overColId, areaId)}
				<div
					class="wx-drop-area"
					style="min-height:{movedCardHeight}px;" />
			{/if}
		{/if}
	</div>
{/if}

<style>
	.wx-list-wrapper {
		height: var(--wx-kanban-column-height);
		overflow: scroll;
	}
	.wx-drop-area {
		width: 100%;
		border-radius: var(--wx-kanban-card-border-radius);
		margin-top: var(--wx-padding);
		border: dotted 1px var(--wx-color-primary);
	}

</style>
