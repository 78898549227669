<script lang="ts">
	import type { IApi, IEditorConfig, TEditorShape } from "@xbs/lib-kanban";
	import { Editor } from "@xbs/svelte-kanban";

	export let api: IApi;
	export let config: IEditorConfig;
	export let shape: TEditorShape[];

</script>

<Editor {api} {config} {shape} />
