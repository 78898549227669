<script>
	import { createEventDispatcher, onMount } from "svelte";
	import { getListHandlers } from "./listnav";
	import { Dropdown } from "@xbs/svelte-wx";

	export let items = [];

	let list;
	let navIndex = null;

	const dispatch = createEventDispatcher();
	const { move, keydown, init, navigate } = getListHandlers();

	const select = () => dispatch("select", { id: items[navIndex]?.id });
	$: init(list, items, i => (navIndex = i), select);

	onMount(() => {
		dispatch("ready", { navigate, keydown, move });
	});

</script>

{#if navIndex !== null}
	<Dropdown cancel={() => navigate(null)}>
		<div
			class="list"
			bind:this={list}
			on:click|stopPropagation={select}
			on:mousemove={move}>
			{#if items.length}
				{#each items as data, index (data.id)}
					<div
						class="item"
						class:disabled={data.disabled}
						class:focus={index === navIndex}
						data-id={data.id}>
						<slot option={data}>{data.name}</slot>
					</div>
				{/each}
			{:else}
				<div class="no-data">No data</div>
			{/if}
		</div>
	</Dropdown>
{/if}

<style>
	.list {
		max-height: 250px;
		overflow-y: auto;
	}

	.item {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		font-family: var(--wx-input-font-family);
		font-size: var(--wx-input-font-size);
		line-height: var(--wx-input-line-height);
		font-weight: var(--wx-input-font-weigth);
		color: var(--wx-input-font-color);
		padding: var(--wx-input-padding);
		cursor: pointer;
	}
	.disabled {
		opacity: 0.8;
		pointer-events: none;
	}
	.item.focus {
		background: var(--wx-background-hover);
	}
	.no-data {
		padding: var(--wx-input-padding);
	}

</style>
