<script>
	import { createEventDispatcher } from "svelte";
	import { Checkbox } from "@xbs/svelte-wx";
	import List from "./helpers/List.svelte";

	export let values = [];
	export let options = [];
	export let textField = "label";
	export let placeholder = "";
	export let disabled = false;
	export let error = false;
	export let checkboxes = false;
	export let limit = null;

	const dispatch = createEventDispatcher();

	let text = "";
	let selected = [];
	let filterOptions;
	let focus = false;

	$: selectedId = selected.map(s => s.id);
	$: filterOptions = options.map(op => {
		op.disabled = false;
		if (
			limit &&
			selected.length &&
			selected.length >= limit &&
			!selectedId.includes(op.id)
		) {
			op.disabled = true;
		}
		return op;
	});

	let navigate;
	let keydown;
	function ready(ev) {
		navigate = ev.detail.navigate;
		keydown = ev.detail.keydown;
	}

	$: selected = options.filter(i => values.includes(i.id));

	function input() {
		filterOptions = text
			? options.filter(i =>
					i[textField].toLowerCase().includes(text.toLowerCase())
			  )
			: options;
		if (filterOptions.length) navigate(0);
		else navigate(null);
	}

	function select(ev) {
		const { id } = ev.detail;

		if (id) {
			values = values.includes(id)
				? values.filter(i => i !== id)
				: [...values, id];

			selected = options.filter(i => values.includes(i.id));

			dispatch("select", { selected });
		}
	}

	function remove(id) {
		values = values.filter(i => i !== id);
	}

</script>

<div
	class="combo {focus && !disabled ? 'focus' : ''} {error ? 'error' : ''} {disabled ? 'disabled' : ''} {selected.length ? 'not-empty' : ''}"
	on:click={() => navigate(0)}
	on:keydown={ev => keydown(ev)}>
	<div class="wrapper">
		<div class="tags">
			{#each selected as tag (tag.id)}
				<div class="tag">
					<slot option={tag}>{tag[textField]}</slot>
					{#if !disabled}
						<i
							class="wxi-close"
							on:click|stopPropagation={() => remove(tag.id)} />
					{/if}
				</div>
			{/each}
		</div>
		<div class="select">
			<input
				type="text"
				class="input"
				bind:value={text}
				on:input={input}
				{placeholder}
				{disabled} />
			<i class="icon wxi-angle-down" />
		</div>
	</div>

	{#if !disabled}
		<List
			let:option
			items={filterOptions}
			on:ready={ready}
			on:select={select}>
			{#if checkboxes}
				<Checkbox
					style="margin-right: 8px; pointer-events: none;"
					value={option.id}
					checked={values.includes(option.id)} />
			{/if}
			<slot {option}>{option.name}</slot>
		</List>
	{/if}
</div>

<style>
	.combo {
		position: relative;
		width: var(--wx-input-width);
	}
	.combo.focus .wrapper {
		border: var(--wx-input-border-focus);
	}
	.combo.disabled .wrapper {
		border: var(--wx-input-border-disabled);
		background: var(--wx-input-background-disabled);
	}
	.combo.disabled .tag {
		background: var(--wx-color-disabled);
		color: var(--wx-color-font-disabled);
	}
	.combo:not(.disabled) .tag {
		padding-right: calc(
			var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
		);
	}
	.combo.disabled input {
		cursor: not-allowed;
		color: var(--wx-color-font-disabled);
	}
	.combo.disabled .icon {
		color: var(--wx-color-font-disabled);
	}
	.combo.error .wrapper {
		border-color: var(--wx-color-danger);
	}
	.combo.error input {
		color: var(--wx-color-danger);
	}
	.combo.error .icon {
		color: var(--wx-color-danger);
	}
	.combo.not-empty .tags {
		gap: var(--wx-multicombo-tag-gap);
		padding: var(--wx-multicombo-tag-gap);
	}
	.combo.not-empty input {
		border-top: var(--wx-input-border);
	}
	.combo.not-empty.focus input {
		border-top: var(--wx-input-border-focus);
	}
	.combo.not-empty.disabled input {
		border-top: var(--wx-input-border-disabled);
	}
	.combo.not-empty.error input {
		border-top-color: var(--wx-color-danger);
	}

	.wrapper {
		border: var(--wx-input-border);
		border-radius: var(--wx-input-border-radius);
		background: var(--wx-input-background);
	}

	.tags {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		overflow: hidden;
	}

	.tag {
		position: relative;
		font-family: var(--wx-input-font-family);
		font-size: var(--wx-input-font-size);
		line-height: var(--wx-input-line-height);
		font-weight: var(--wx-input-font-weigth); /* typo in wx theme */
		text-align: var(--wx-input-text-align);
		color: var(--wx-input-font-color);
		border: var(--wx-multicombo-tag-border);
		border-radius: var(--wx-multicombo-tag-border-radius);
		background: var(--wx-multicombo-tag-background);
		padding: var(--wx-multicombo-tag-pading); /* typo in wx theme */
	}

	.wxi-close {
		position: absolute;
		right: var(--wx-input-icon-indent);
		top: 50%;
		transform: translateY(-50%);
		font-size: var(--wx-input-icon-size);
		line-height: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		width: var(--wx-input-icon-size);
		height: var(--wx-input-icon-size);
		border-radius: var(--wx-input-border-radius);
		color: var(--wx-input-icon-color);
		cursor: pointer;
	}
	.wxi-close:hover {
		color: var(--wx-color-primary);
	}

	.select {
		position: relative;
	}

	input {
		display: block;
		width: 100%;
		height: var(--wx-input-height);
		outline: none;
		background: transparent;
		border: none;
		border-radius: 0;
		font-family: var(--wx-input-font-family);
		font-size: var(--wx-input-font-size);
		line-height: var(--wx-input-line-height);
		font-weight: var(--wx-input-font-weigth);
		text-align: var(--wx-input-text-align);
		color: var(--wx-input-font-color);
		padding: var(--wx-input-padding);
		padding-right: calc(
			var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
		);
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
	}

	.icon {
		position: absolute;
		right: var(--wx-input-icon-indent);
		top: 50%;
		transform: translateY(-50%);
		font-size: var(--wx-input-icon-size);
		line-height: 1;
		width: var(--wx-input-icon-size);
		height: var(--wx-input-icon-size);
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		user-select: none;
		color: var(--wx-input-icon-color);
	}
	.icon:before {
		display: block;
	}

</style>
