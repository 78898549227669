<script lang="ts">
	interface IData {
		label?: string;
		avatar?: string;
		avatarColor?: string;
	}

	export let data: IData = {
		label: "",
		avatar: "",
		avatarColor: "",
	};
	export let noTransform = false;
	export let size = "normal";
	export let border = true;

	$: firstLetters = data.label
		.split(" ")
		.map(name => name[0])
		.join("");

	let style = "";
	$: {
		if (data.avatarColor) {
			style = `background: ${data.avatarColor}; color: var(--wx-color-primary-font);`;
		}
	}

</script>

<div class="wx-user {size}" class:border {style}>
	{#if data.avatar}
		<img src={data.avatar} alt={data.label} />
	{:else if noTransform}{data.label}{:else}{firstLetters}{/if}
</div>

<style>
	.wx-user {
		border-radius: 50%;
		background: var(--wx-kanban-background);

		color: var(--wx-color-font);
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		background: var(--wx-kanban-background);
	}

	.wx-user.normal {
		width: var(--wx-kanban-user-icon-size);
		height: var(--wx-kanban-user-icon-size);
		font-size: var(--wx-font-size);
	}

	.wx-user.small {
		width: calc(var(--wx-kanban-user-icon-size) - 8px);
		height: calc(var(--wx-kanban-user-icon-size) - 8px);
		font-size: var(--wx-font-size-sm);
	}

	.wx-user.border {
		border: solid var(--wx-kanban-card-background) 2px;
	}

	.wx-user img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.wx-user:nth-child(2) {
		transform: translateX(-15px);
	}
	.wx-user:nth-child(3) {
		transform: translateX(-20px);
	}
	.wx-user:nth-child(4) {
		transform: translateX(-30px);
	}

</style>
