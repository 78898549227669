<script lang="ts">
	import { writable } from "svelte/store";
	import { Uploader } from "@xbs/svelte-wx";
	import UploaderList from "./UploaderList.svelte";

	import type { Writable } from "svelte/store";
	import type { IAttachment, TFilesFieldShape, ICard } from "@xbs/lib-kanban";

	export let field: TFilesFieldShape;
	export let values: Writable<ICard>;

	const files = writable($values[field.key] || []) as Writable<IAttachment[]>;

	let ready = false;
	$: {
		if (ready) {
			$values[field.key] = $files;
		}
		ready = true;
	}

	function uploadFn(files: any[]) {
		return files.map(rec => {
			const formData = new FormData();
			formData.append("upload", rec.file);

			const config = {
				method: "POST",
				body: formData,
			};

			return fetch(field.uploadURL, config)
				.then(res => res.json())
				.then(
					data => {
						rec.id = data.id;
						return data;
					},
					() => ({ id: rec.id, status: "error" })
				)
				.catch();
		});
	}

</script>

<UploaderList data={files} />
<Uploader bind:data={$files} uploadURL={uploadFn} {...field.config} />
