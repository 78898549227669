<script lang="ts">
	import { createEventDispatcher, getContext } from "svelte";
	import type {
		IColumn,
		TAreasMeta,
		IColumnShape,
		TEventDispatcher,
		TID,
		TMenuItemsFn,
		IApi,
	} from "@xbs/lib-kanban";

	import { ContextMenu } from "@xbs/svelte-menu";

	import HeaderCard from "./HeaderCard.svelte";

	export let columns: IColumn[];
	export let areasMeta: TAreasMeta;
	export let api: IApi;
	export let contentEl: HTMLElement;

	const _ = getContext<any>("wx-i18n").getGroup("kanban");
	const dispatch: TEventDispatcher = createEventDispatcher();

	const { readonly, columnShape } = api.getReactiveState();

	const fixed = $columnShape.fixedHeaders !== false;

	const getMenuItems = (
		column: IColumn,
		columnIndex: number,
		columns: IColumn[],
		columnShape: IColumnShape
	) => {
		const menuItems = (
			columnShape.menu.items as TMenuItemsFn<{
				column: IColumn;
				columns: IColumn[];
				columnIndex: number;
			}>
		)({
			columns,
			columnIndex,
			column,
		});

		if (!menuItems || !menuItems.length) {
			return null;
		}

		return menuItems.map(item => {
			return {
				...item,
				text: _(item.text),
				css: item.disabled ? "disabled" : "",
			};
		});
	};

	let columnMenuItems = {};
	$: {
		columns.forEach((column, i) => {
			columnMenuItems[column.id] = getMenuItems(
				column,
				i,
				columns,
				$columnShape
			);
		});
	}

	function handleMove(column: TID, dir: "left" | "right") {
		const columnIndex = columns.findIndex(c => c.id === column);
		const beforeIndex = dir === "left" ? columnIndex - 1 : columnIndex + 2;
		const before = columns[beforeIndex]?.id;

		dispatch("action", {
			action: "move-column",
			data: {
				id: column,
				before,
			},
		});
	}

	let renamingCard = null;

	function handleMenuAction(ev: any) {
		const { action, item } = ev.detail;

		if (action) {
			if (action.onClick) {
				action.onClick({
					id: action.id,
					item: action,
					column: item,
				});
				return;
			}

			switch (action.id) {
				case "add-card":
					dispatch("action", {
						action: "add-card",
						data: {
							columnId: item.id,
							card: {
								label: _("Untitled"),
							},
						},
					});
					break;
				case "set-edit":
					if ($readonly.edit) renamingCard = item.id;
					break;
				case "delete-column":
					dispatch("action", {
						action: "delete-column",
						data: {
							id: item.id,
						},
					});
					break;
				case "move-column:left":
					handleMove(item.id, "left");
					break;
				case "move-column:right":
					handleMove(item.id, "right");
					break;
			}
		}
	}

	let showMenu = null;
	// FIXME - temporary fix for the https://tracker.webix.io/issue/SVAR-1166
	const showMenuFix = ev => {
		ev.cancelBubble = true;
		return showMenu(ev);
	};
	let menuItems = [];

	function resolver(item) {
		menuItems = columnMenuItems[item] || [];
		return columns.find(c => c.id === item);
	}

	const handleColumnActions = ({ detail: { action, data } }) => {
		if (action === "close-column-input") renamingCard = null;

		dispatch("action", { action, data });
	};

</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="wx-header" class:fixed on:click={showMenuFix}>
	{#each columns as column (column.id)}
		<HeaderCard
			{column}
			isMenuVisible={$columnShape.menu.show && columnMenuItems[column.id]?.length}
			{contentEl}
			{api}
			{areasMeta}
			renaming={renamingCard === column.id}
			on:action={handleColumnActions} />
	{/each}
</div>

<ContextMenu
	at={'left-bottom'}
	options={menuItems}
	bind:handler={showMenu}
	{resolver}
	dataKey="menuId"
	on:click={handleMenuAction} />

<style>
	/* [todo] add ability to disable list item to wx, remove this block  */
	:global([data-id="wx-list-disabled"]) {
		opacity: 0.5;
		pointer-events: none;
	}
	.wx-header {
		display: flex;
		flex-direction: row;
		position: relative;

		padding: 12px 0 var(--wx-padding) 0;

		height: var(--wx-kanban-header-height);
		min-height: var(--wx-kanban-header-height);
	}

	.wx-header.fixed {
		position: sticky;
		z-index: 100;
		top: 0;
		background: var(--wx-kanban-background);
	}

</style>
