<script>
	import { Material } from "@xbs/svelte-wx";

	export let fonts = true;
	const SLOTS = $$slots;

</script>

{#if SLOTS && SLOTS.default}
	<Material {fonts}>
		<slot />
	</Material>
{:else}
	<Material {fonts} />
{/if}

<style global>
	.wx-material-theme * {
		box-sizing: border-box;
	}
	.wx-material-theme {
		--wx-field-width: 100%;
		--wx-theme-name: material;

		/* Kanban */
		--wx-kanban-background: #f1f1f1;

		--wx-kanban-column-width: 300px;
		--wx-kanban-column-height: 300px;

		--wx-kanban-toolbar-height: 56px;
		--wx-kanban-toolbar-align: center;
		--wx-kanban-toolbar-justify: flex-start;
		--wx-kanban-toolbar-control-hover: rgba(0, 0, 0, 0.07);
		--wx-kanban-toolbar-control-active: rgba(0, 0, 0, 0.15);
		--wx-kanban-toolbar-border: var(--wx-border);

		--wx-kanban-card-field-padding: 12px;
		--wx-kanban-content-background: var(--wx-background);
		--wx-kanban-card-border: var(--wx-border);
		--wx-kanban-card-border-radius: 6px;
		--wx-kanban-header-border-radius: var(--wx-kanban-card-border-radius);

		--wx-kanban-row-line: var(--wx-border);

		--wx-kanban-user-icon-size: 36px;

		--wx-kanban-header-height: 64px;
		--wx-kanban-editor-width: 569px;
		--wx-kanban-editor-height: auto;
		--wx-kanban-editor-x-padding: 20px;
		--wx-kanban-editor-background: var(--wx-kanban-content-background);
		--wx-kanban-editor-top-border: none;

		--wx-kanban-over-limit-color: var(--wx-color-danger);
		--wx-kanban-collapsed-column-width: 44px;
		--wx-kanban-z-index: 1;

		--wx-progress-height: 4px;
		--wx-kanban-progress-inactive-color: #dbdbdb;

		--wx-kanban-menu-min-width: 100px;

		--wx-kanban-shadow: none;
		--wx-kanban-box-border: var(--wx-border);

		--wx-kanban-collapsed-padding: var(--wx-padding);
		--wx-kanban-collapsed-margin: 0px;
		--wx-kanban-collapsed-background: transparent;
		--wx-kanban-collapsed-background-hover: #dfdfdf;
		/* End Kanban */
	}

	.wx-styled-scroll {
		--wx-styled-scroll-track-color: #fafafa;
		--wx-styled-scroll-thumb-color: #babac0;
		--wx-styled-scroll-width: 16px;
	}
	.wx-styled-scroll * {
		scrollbar-width: thin;
	}
	.wx-styled-scroll *::-webkit-scrollbar {
		width: var(--wx-styled-scroll-width);
	}
	.wx-styled-scroll *::-webkit-scrollbar-track {
		background-color: var(--wx-styled-scroll-track-color);
	}
	.wx-styled-scroll *::-webkit-scrollbar-thumb {
		background-color: var(--wx-styled-scroll-thumb-color);
		border-radius: var(--wx-styled-scroll-width);
		border: 4px solid var(--wx-styled-scroll-track-color);
	}
	.wx-styled-scroll *::-webkit-scrollbar-button {
		display: none;
	}

</style>
